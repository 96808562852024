import React, { useContext, useEffect, useState } from "react";
import "./HistoryTab.css";
import SearchBar from "./search_bar/SearchBar";
import NoData from "./no_data_found/NoData";
// import TransactionHistoryCard from "./transaction_history_card/TransactionHistoryCard";
import AxiosGet from "../../../api's/AxiosGet";
import DataContext from "../../../context/DataContext";
import HistoryContext from "../../../context/HistoryDataProvider";
import HistoryPaginate from "./history_paginate/HistoryPaginate";
// import AllTransaction from "./all_transaction/AllTransaction";
// import GameHistory from "./game_history/GameHistory";
// import ReferralHistory from "./referral_history/ReferralHistory";
import ButtonBar from "./button_bar/ButtonBar";
import ShowAll from "./show_all/ShowAll";
import ShowDeposit from "./show_deposit/ShowDeposit";
import ShowWithdraw from "./show_withdraw/ShowWithdraw";
import ShowGame from "./show_game/ShowGame";
import ShowReferral from "./show_referral/ShowReferral";
import ShowBonus from "./show_bonus/ShowBonus";
import ShowPenalty from "./show_penalty/ShowPenalty";

const HistoryTab = () => {
  let limit = 100;
  // const [gameButtonClick, setGameButtonClick] = useState(false);
  // const [referralButtonClick, setReferralButtonClick] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [searchRoomCode, setSearchRoomCode] = useState("");
  console.log("entered room code value is:-", searchRoomCode);
  // const [curentPage, setCurrentPage] = useState()

  const [mapHistoryData, setMapHistoryData] = useState([]);
  // const [apiData, setApiData] = useState({});

  const { state } = useContext(DataContext);
  let user_id = state.user_data._id;

  const { historyState, historyDispatch } = useContext(HistoryContext);
  // console.log("History Data from history context :- ", historyState);
  // console.log("totalPenalty:-", historyState.totalPenalty);

  const access_token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${access_token}`,
  };

  // const gameHistory = async () => {
  //   let res_data = await AxiosGet(`game/history/user/${user_id}`, { headers });
  //   if (res_data) {
  //     historyDispatch({ type: "GAME_HISTORY", data: res_data.data });
  //   }
  // };

  const depositHistory = async () => {
    let res_data = await AxiosGet(
      `temp/deposite/${user_id}?page=${pageNo}&_limit=${limit}`,
      { headers }
    );
    if (res_data) {
      console.log("History data is :- ", res_data);

      historyDispatch({ type: "DEPOSIT_HISTORY", data: res_data.data });
      // setApiData(res_data.data);
      setNumberOfPages(res_data.data.totalPages);
    }
  };

  // useEffect(allData, [historyState]);

  useEffect(() => {
    depositHistory();
  }, [pageNo]);

  // const penaltyData = () => {
  //   let data = historyState.depositHistory.ress;
  //   if (data) {
  //     let filtered_data = data.filter(
  //       (val) => val.status !== "FAILED" && val.reqType === "penalty"
  //     );
  //     setMapHistoryData(filtered_data);
  //   }
  //   setGameButtonClick(false);
  //   setReferralButtonClick(false);
  // };

  // const withdrawlData = () => {
  //   let data = historyState.depositHistory.ress;
  //   if (data) {
  //     let filtered_data = data.filter(
  //       (val) => val.status !== "FAILED" && val.reqType === "withdraw"
  //     );
  //     setMapHistoryData(filtered_data);
  //   }
  //   setGameButtonClick(false);
  //   setReferralButtonClick(false);
  // };

  // console.log(
  //   "show game and show referrals",
  //   !historyState.showGame || !historyState.showReferral
  // );

  return (
    <>
      <ButtonBar historyDispatch={historyDispatch} />
      {historyState.showGame && (
        <SearchBar setSearchRoomCode={setSearchRoomCode} />
      )}

      {historyState.showAll && (
        <ShowAll data={historyState.depositHistory.ress} />
      )}

      {historyState.showDeposit && (
        <ShowDeposit data={historyState.depositHistory.ress} />
      )}

      {historyState.showWintdraw && (
        <ShowWithdraw data={historyState.depositHistory.ress} />
      )}

      {historyState.showGame && (
        <ShowGame user_id={user_id} searchRoomCode={searchRoomCode} />
      )}

      {historyState.showReferral && (
        <ShowReferral user_id={user_id} searchRoomCode={searchRoomCode} />
      )}

      {historyState.showBonus && (
        <ShowBonus data={historyState.depositHistory.ress} />
      )}

      {historyState.showPenalty && (
        <ShowPenalty
          data={historyState.depositHistory.ress}
          historyDispatch={historyDispatch}
        />
      )}

      {historyState.depositHistory.totalPages > 1 &&
        !historyState.showGame &&
        !historyState.showReferral && (
          <HistoryPaginate
            currentPage={pageNo}
            setPageNo={setPageNo}
            totalPageNo={numberOfPages}
          />
        )}

      {/* {!gameButtonClick && !referralButtonClick ? ( */}
      {/* // <div className="history-card "> */}
      {/* {mapHistoryData.length < 1 ? (
            <NoData />
          ) : (
            mapHistoryData.map((val, index) => {
              return (
                <TransactionHistoryCard
                  key={index}
                  title={val.reqType}
                  status={val.status}
                  date={val.createdAt}
                  amount={val.amount}
                  // balance={val.closingBalance}
                />
              );
            })
          )} */}

      {/* {historyState.depositHistory.totalPages > 1 && (
        <HistoryPaginate
          currentPage={pageNo}
          setPageNo={setPageNo}
          totalPageNo={numberOfPages}
        />
      )} */}
      {/* </div> */}
      {/* ) : gameButtonClick ? (
        <div>
          <GameHistory limit={limit} user_id={user_id} headers={headers} />
        </div>
      ) : (
        <div>
          <ReferralHistory />
        </div>
      )} */}

      {/* <div className="history-card game-history-card"> */}
      {/* <AllTransaction limit={limit} headers={headers} user_id={user_id} /> */}
      {/* <GameHistory limit={limit} headers={headers} user_id={user_id} /> */}
      {/* </div> */}
    </>
  );
};

export default HistoryTab;
