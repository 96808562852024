import React from "react";
import AddCashData from "./add_cash_data/AddCashData";
import Navbar from "../../components/navbar/Navbar";
import Leftside from "../../components/leftside/Leftside";

const AddCash = () => {
  return (
    <>
      <Navbar />
      <Leftside>
        <AddCashData />
      </Leftside>
    </>
  );
};

export default AddCash;
