import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import DataProvider from "./context/DataProvider";
import AxiosPost from "./api's/AxiosPost";
import Test from "./Test";
import Loading from "./components/loading/Loading";
import Maintenance from "./components/maintenance/Maintenance";
import History from "./pages/history/History";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <DataProvider>
    <App />
    {/* <Test /> */}
    {/* <Loading /> */}
    {/* <Maintenance /> */}
    {/* <NavbarCopy /> */}
    {/* <History /> */}
  </DataProvider>
);
