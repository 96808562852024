import React, { useState } from "react";
import "./CancleCard.css";
import Leftside from "../../../../components/leftside/Leftside";
import LeftSideCard from "../../../../components/card/LeftSideCard";

const CancleCard = ({ reason, setReason, Result, setStatus }) => {
  // const [reason, setReason] = useState("");
  console.log(reason);
  const handleSubmit = (e) => {
    e.preventDefault();
    Result(e);
    //  console.log("Form Submit is clicked...");
    //   const formData = new FormData();
    //   formData.append("reason", reason);
    //   console.log("Form data is :- ", formData .get("reason"));
  };

  return (
    <>
      {/* <LeftSideCard> */}
      <div className="cancle-card-container">
        <div
          className="cancle-card-close-button"
          onClick={() => setStatus(null)}
        >
          X
        </div>

        <div className="cancle-card-heading">
          <h3>We Would Like To Know More</h3>
          <h5>Select Reason For Cancelling</h5>
        </div>

        <div className="cancle-reason-container">
          <div
            className={
              reason === "No Room Code"
                ? "cancle-reason-active"
                : "cancle-reason-button"
            }
            onClick={(e) => {
              setReason(e.target.innerText);
            }}
          >
            No Room Code
          </div>
          <div
            className={
              reason === "Not Joined"
                ? "cancle-reason-active"
                : "cancle-reason-button"
            }
            onClick={(e) => {
              setReason(e.target.innerText);
            }}
          >
            Not Joined
          </div>
          <div
            className={
              reason === "Not Playing"
                ? "cancle-reason-active"
                : "cancle-reason-button"
            }
            onClick={(e) => {
              setReason(e.target.innerText);
            }}
          >
            Not Playing
          </div>
          <div
            className={
              reason === "Don't Want To Play"
                ? "cancle-reason-active"
                : "cancle-reason-button"
            }
            onClick={(e) => {
              setReason(e.target.innerText);
            }}
          >
            Don't Want To Play
          </div>
          <div
            className={
              reason === "Opponent Abusing"
                ? "cancle-reason-active"
                : "cancle-reason-button"
            }
            onClick={(e) => {
              setReason(e.target.innerText);
            }}
          >
            Opponent Abusing
          </div>
          <div
            className={
              reason === "Game Not Started"
                ? "cancle-reason-active"
                : "cancle-reason-button"
            }
            onClick={(e) => {
              setReason(e.target.innerText);
            }}
          >
            Game Not Started
          </div>
          <div
            className={
              reason === "Others"
                ? "cancle-reason-active"
                : "cancle-reason-button"
            }
            onClick={(e) => {
              setReason(e.target.innerText);
            }}
          >
            Others
          </div>
        </div>

        {!reason ? (
          <button className="cancle-confirm-button-deactive" disabled>
            CONFIRM
          </button>
        ) : (
          <form onSubmit={handleSubmit} className="cancle-card-form">
            <button className="cancle-confirm-button-active" type="submit">
              CONFIRM
            </button>
          </form>
        )}
      </div>
      {/* </LeftSideCard> */}
    </>
  );
};

export default CancleCard;
