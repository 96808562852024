import React, { useEffect, useState } from "react";
import "./ResendButton.css";

const Counter = ({ count, sendOTPhandler }) => {
  console.log("Resend button is triggered...");
  const [counter, setCounter] = useState(count);
  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }
  }, [counter]);

  return (
    <>
      {counter > 0 ? (
        <button className="resend-otp-button-disabled" disabled>
          Resend {counter}
        </button>
      ) : (
        <button className="resend-otp-button-active" onClick={sendOTPhandler}>
          Resend
        </button>
      )}
    </>
  );
};

export default Counter;
