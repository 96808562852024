import React, { useContext } from "react";
import "./TopButton.css";
import { Link } from "react-router-dom";
import ArrowDropDownCircleTwoToneIcon from "@mui/icons-material/ArrowDropDownCircleTwoTone";
import DataContext from "../../../../context/DataContext";

const TopButton = () => {
  const { state, dispatch } = useContext(DataContext);
  return (
    <>
      <div className="addcash-top-header-heading">
        <Link to="/wallet">
          <button className="addcash-top-header-back-button">Back</button>
        </Link>

        <button className="addcash-top-header-heading-button">
          <div
            className="addcash-top-header-guide-button"
            onClick={() => dispatch({ type: "CLICKED_FOR_GUIDE" })}
          >
            <ArrowDropDownCircleTwoToneIcon sx={{ color: "white" }} />
            Guide
          </div>
        </button>
      </div>
    </>
  );
};

export default TopButton;
