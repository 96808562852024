import React, { useRef } from "react";
import "./SearchBar.css";

const SearchBar = ({ setSearchRoomCode }) => {
  const inputRef = useRef();
  return (
    <>
      <div className="search-bar-container">
        <input
          type="text"
          placeholder="Enter Room Code"
          ref={inputRef}
          onChange={(e) => setSearchRoomCode(e.target.value)}
        />
        <button
          onClick={() => {
            let inputValue = inputRef.current.value;
            setSearchRoomCode(inputValue);
          }}
        >
          Search
        </button>
      </div>
    </>
  );
};

export default SearchBar;
