import React from "react";
import "./OpenBattleCard.css";
import moneyimg from "../../../../../assets/images/money.png";
import DeleteIcon from "@mui/icons-material/Delete";
import loadingGif from "../../../../../assets/images/gif/loading_old.gif";
import { Link } from "react-router-dom";
import acceptSound from "../../../../../assets/audio/accept.mp3";
import playSound from "../../../../../assets/audio/play.mp3";
import userimage from "../../../../../assets/images/avtar/user.png";

const OpenBattleCard = ({
  allgame,
  user,
  deleteChallenge,
  getPost,
  RejectGame,
  winamountFun,
  AcceptChallang,
  updateChallenge,
}) => {
  console.log("open battle card triggered....");
  // console.log("Data at open battle is:- ", allgame, user);
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  // const websocketURL = process.env.REACT_APP_SOCKET_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  return (
    <div className="open-battle-card-container">
      <div className="open-battle-card-heading">
        <div>
          Challenge From{" "}
          <span className="open-battle-card-heading-span">
            {allgame.createdBy.name}
          </span>
        </div>

        {user == allgame.createdBy._id && allgame.status == "new" && (
          <button
            className="open-battle-delete-button"
            onClick={() => deleteChallenge(allgame._id)}
          >
            <DeleteIcon sx={{ color: "rgb(220, 53, 70)" }} />
          </button>
        )}

        {user == allgame.createdBy._id && allgame.status == "requested" && (
          <div className="start-reject-button-container">
            <Link
              to={{
                pathname: `/roomcode/${allgame._id}`,
                state: { prevPath: window.location.pathname },
              }}
              onClick={(e) => getPost(allgame._id)}
              style={{ bottom: "0", borderRadius: "3px" }}
            >
              <button className="open-bettle-start-button">Start</button>
            </Link>

            <button
              className="open-bettle-reject-button"
              onClick={() => RejectGame(allgame._id)}
            >
              Reject
            </button>
          </div>
        )}
      </div>

      <div className="open-battle-card-line"></div>

      <div className="open-battle-card-details">
        <div className="open-battle-entry-details">
          <div>Enrty Fee</div>
          <div className="open-battle-entry-amount">
            <img width="22px" src={moneyimg} alt="money image" />
            <span> {allgame.gameAmount} </span>
          </div>
        </div>

        {user == allgame.createdBy._id && allgame.status == "new" && (
          <img width="30px" height="30px" src={loadingGif} alt="Loading" />
        )}

        {user != allgame.createdBy._id && allgame.status == "new" && (
          <button
            className="open-battle-play-button"
            onClick={() => AcceptChallang(allgame._id)}
          >
            Play
          </button>
        )}

        {user !== allgame.createdBy._id && allgame.status == "requested" && (
          <div className="requested-cancle-button-container">
            <button className="open-battle-requested-button">Requested</button>
            <button
              className="open-battle-cancel-button"
              onClick={() => RejectGame(allgame._id)}
            >
              Cancel
            </button>
          </div>
        )}

        {user == allgame.createdBy._id && allgame.status == "requested" && (
          <div>
            <audio src={acceptSound} autoPlay></audio>
            <div>
              <div>
                {allgame.acceptedBy.avatar ? (
                  <img
                    className="open-battle-requested-avtar"
                    src={baseUrl + `${allgame.acceptedBy.avatar}`}
                    alt=""
                    width="40px"
                    height="40px"
                  />
                ) : (
                  <img
                    className="open-battle-creater-avtar"
                    src={userimage}
                    alt=""
                    width="40px"
                    height="40px"
                  />
                )}
              </div>
              <div>
                <span>{allgame.acceptedBy.name}</span>
              </div>
            </div>
          </div>
        )}

        {/* Start */}
        {user !== allgame.createdBy._id && allgame.status == "running" && (
          <div>
            {console.log(
              "start request is accepted by user:- now start button activate:--"
            )}
            <audio src={playSound} autoPlay></audio>
            <Link
              to={{
                pathname: `/roomcode/${allgame._id}`,
                state: { prevPath: window.location.pathname },
              }}
              onClick={(e) => updateChallenge(allgame._id)}
            >
              <button className="open-bettle-start-button special-button ">
                Start
              </button>
            </Link>
          </div>
        )}
        {/* End */}

        <div className="open-battle-prize-details">
          <div>Prize</div>
          <div className="open-battle-entry-amount">
            <img width="22px" src={moneyimg} alt="money image" />
            <span>{winamountFun(allgame.gameAmount)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenBattleCard;
