import React from "react";
import axios from "axios";
import { ToastError } from "../helper/tost_helper/ToastError";

const access_token = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${access_token}`,
};
let default_header = { headers };
const AxiosGet = async (url_end, data = default_header) => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  // console.log("Data object is :-", data);

  try {
    let response = await axios.get(`${baseUrl}${url_end}`, data);

    if (response.status === 101) {
      ToastError(response.data.msg, 2000);
    } else if (response.status === 200) {
      // console.log(response);
      return response;
    }
  } catch (error) {
    console.log("Error:- 404 found", error.message);
    ToastError(error.code, 2000);
  }
};

export default AxiosGet;
