import React, { useContext } from "react";
import "./HomeData.css";
import AnnouncementTwoToneIcon from "@mui/icons-material/AnnouncementTwoTone";
import ArrowDropDownCircleTwoToneIcon from "@mui/icons-material/ArrowDropDownCircleTwoTone";
import ludoClassic from "../../assets/images/LandingPage_img/ludo_classic.png";
import ludoPopular from "../../assets/images/LandingPage_img/ludo_popular.png";

import ludoQuick from "../../assets/images/LandingPage_img/ludo_quick.png";
import snakeladder from "../../assets/images/LandingPage_img/ludo_snake.png";
import DataContext from "../../context/DataContext";
import { Link } from "react-router-dom";

const HomeData = () => {
  const { dispatch } = useContext(DataContext);

  return (
    <>
      <div className="home-heading">
        <div>Games</div>
        <button className="home-heading-button">
          <div
            className="guide-button"
            onClick={() => dispatch({ type: "CLICKED_FOR_GUIDE" })}
          >
            <ArrowDropDownCircleTwoToneIcon sx={{ color: "white" }} />
            Guide
          </div>
        </button>
      </div>
      <div className="home-games-container">
        <div className="home-game ludo-classic">
          <div>Live</div>
          <Link to="/home/Ludo Classics">
            {" "}
            <img src={ludoClassic} />{" "}
          </Link>
        </div>
        <div className="home-game ludo-popular">
          <div>Coming Soon</div>
          <img src={ludoPopular} />
        </div>
        <div className="home-game ludo-quick">
          <div>Coming Soon</div>
          <img src={ludoQuick} />
        </div>
        <div className="home-game snake-ladders">
          <div>Coming Soon</div>
          <img src={snakeladder} />
        </div>
      </div>
    </>
  );
};

export default HomeData;
