import React, { useContext } from "react";
import "./KycCard.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import DataContext from "../../context/DataContext";
import { Link } from "react-router-dom";

const Kyc = () => {
  const { state } = useContext(DataContext);

  let kyc_status = state.user_data.verified;
  // let kyc_status = "pending";
  return (
    <>
      <div
        className={
          kyc_status === "unverified" || kyc_status === "pending"
            ? "profile-top-kyc-unverified"
            : "profile-top-kyc"
        }
      >
        <div>
          <p>KYC Status</p>
          <div className="kyc-status">
            {kyc_status}
            {kyc_status == "verified" ? (
              <CheckCircleOutlineIcon sx={{ color: "#437c3c" }} />
            ) : (
              <CancelIcon sx={{ color: "#dc3546" }} />
            )}
          </div>
        </div>

        {kyc_status === "verified" ? (
          <button className="kyc-button-verified" disabled>
            {" "}
            Verified KYC Details
          </button>
        ) : (
          <Link to="/kyc">
            <button className="kyc-button-unverified">
              {/* {" "} */}
              Click to Verify{" "}
            </button>
          </Link>
        )}
      </div>
    </>
  );
};

export default Kyc;
