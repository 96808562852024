import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Support from "./pages/support/Support";
import Landing from "./pages/landing/Landing";
import TermsConditions from "./pages/terms-conditions/TermsConditions";
import Rightside from "./components/rightside/Rightside";
import Wallet from "./pages/wallet/Wallet";
import ReferNEarn from "./pages/refer/ReferNEarn";
import Profile from "./pages/profile/Profile";
import { useContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataContext from "./context/DataContext";
import Loading from "./components/loading/Loading";
import Maintenance from "./components/maintenance/Maintenance";
import AxiosGet from "./api's/AxiosGet";
import History from "./pages/history/History";
import HistoryPaginate from "./pages/history/history_tab/history_paginate/HistoryPaginate";
import Game from "./pages/game/Game";
import Kyc from "./pages/kyc/Kyc";
import RoomCode from "./pages/room_code/RoomCode";
import Rules from "./pages/rules/Rules";
import CancleCard from "./pages/room_code/room_code_data/cancle_card/CancleCard";
import WinCard from "./pages/room_code/room_code_data/win_card/WinCard";
import ViewModal from "./pages/game/game_data/running_battle/running_battle_card/view_modal/ViewModal";
import RulesModal from "./components/rules_modal/RulesModal";
import AddCash from "./pages/add_cash/AddCash";
import WithdrawCash from "./pages/withdraw_cash/WithdrawCash";

function App() {
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isMaintenance, setIsmaintenance] = useState(true);

  let user_token = localStorage.getItem("token");

  let getWebsiteStatus = async () => {
    let web_response = await AxiosGet("settings/data");
    if (web_response) {
      dispatch({ type: "WEBSITE_SETTING", data: web_response });
      setIsmaintenance(web_response.data.websiteStatus);
      if (user_token) {
        const headers = {
          Authorization: `Bearer ${user_token}`,
        };
        let user_response = await AxiosGet(`me`, { headers });

        if (user_response) {
          dispatch({ type: "AUTH_SUCCESS" });
          dispatch({ type: "USER_DATA", data: user_response.data });
        } else {
          localStorage.removeItem("token");
        }
      } else {
        console.log("No user token found");
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getWebsiteStatus();
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: state.isAuth ? <Home /> : <Landing />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/support",
      element: <Support />,
    },
    {
      path: "/term-conditions",
      element: <TermsConditions />,
    },
    {
      path: "/wallet",
      element: state.isAuth ? <Wallet /> : <Login />,
    },
    {
      path: "/refer",
      element: state.isAuth ? <ReferNEarn /> : <Login />,
    },
    {
      path: "/profile",
      element: state.isAuth ? <Profile /> : <Login />,
    },
    {
      path: "/history",
      element: state.isAuth ? <History /> : <Login />,
    },
    {
      path: "/home/:game",
      element: state.isAuth ? <Game /> : <Login />,
    },
    {
      path: "/roomcode/:id",
      element: state.isAuth ? <RoomCode /> : <Login />,
    },
    {
      path: "/kyc",
      element: state.isAuth ? <Kyc /> : <Login />,
    },
    {
      path: "/rules",
      element: state.isAuth ? <Rules /> : <Login />,
    },
    {
      path: "/addcash",
      element: state.isAuth ? <AddCash /> : <Login />,
    },
    {
      path: "/withdraw",
      element: < WithdrawCash />,
    },
    {
      path: "*",
      element: <div>No Page available</div>,
    },
    // {
    //   path: "*",
    //   element: <Login />,
    // },
  ]);
  return (
    <>
      <ToastContainer
      // transition:Bounce
      />
      < RulesModal />
      {isLoading ? (
        <Loading />
      ) : !isMaintenance ? (
        <Maintenance />
      ) : (
        <>
          <RouterProvider router={router}></RouterProvider>
          <Rightside />
        </>
      )}
    </>
  );
}

export default App;
