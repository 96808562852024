import React from 'react'
import "./Bottombar.css"
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import GroupIcon from '@mui/icons-material/Group';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { NavLink } from 'react-router-dom';

const Bottombar = () => {
  return (
    <div className='bottombar-container'>
        <div className='bottombar-icons'>
            <NavLink to="/">
            <div className='bottombar-icon'>
                <HomeIcon sx={{ fontSize:28, color:"white"}}/>
            <div className='bottombar-icon-text'>Home</div>
            </div>
            </NavLink>

            <NavLink to="/wallet">
            <div className='bottombar-icon'>
                <AccountBalanceWalletIcon sx={{ fontSize:28, color:"white"}}/>
            <div className='bottombar-icon-text'>Wallet</div>
            </div>
            </NavLink>

            <NavLink to="/refer">
            <div className='bottombar-icon'>
                <GroupIcon sx={{ fontSize:28, color:"white"}}/>
            <div className='bottombar-icon-text'>Refer</div>
            </div>
            </NavLink>

            <NavLink to="/support">
            <div className='bottombar-icon'>
                <SupportAgentIcon sx={{ fontSize:28, color:"white"}}/>
            <div className='bottombar-icon-text'>Support</div>
            </div>
            </NavLink>

            <NavLink to="/profile">
            <div className='bottombar-icon'>
                <AccountCircleIcon sx={{ fontSize:28, color:"white"}}/>
            <div className='bottombar-icon-text'>Profile</div>
            </div>
            </NavLink>
            
           
        </div>
    </div>
  )
}

export default Bottombar