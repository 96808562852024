import React from 'react'
import { Link } from 'react-router-dom'

const ContactUs = () => {
  return (
    <div className='terms-conditions-content'>
                  support@kd124.com <br />
                  <br />
                  www.kd124.com
                  <br />
                  <br />
                  {/* <Link
                    style={{
                      fontFamily: "Poppins",
                      textDecoration: "underline",
                    }}
                    to="/contact-us"
                  >
                    Contact Us
                  </Link> */}
               
    </div>
  )
}

export default ContactUs