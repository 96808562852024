import React from "react";
import "./RoomCodeGameRules.css";

const RoomCodeGameRules = () => {
  return (
    <>
      <div className="room-code-rules-container">
        <div className="room-code-rules-heading">Game Rules</div>

        <div className="room-code-rules-body">
          <ul>
            <li>Record every game while playing.</li>
            <li>For cancellation of game, video proof is necessary.</li>
            <li>
              {" "}
              ◉ महत्वपूर्ण नोट:कृपया गलत गेम परिणाम अपलोड न करें, अन्यथा आपके
              वॉलेट बैलेंस पर penalty लगाई जायगी। गलत रिजल्ट अपडेट करने पर 50
              रुपये पेनल्टी लगेगी।
            </li>
            <li>
              ◉ महत्वपूर्ण नोट: यदि आप गेम के परिणामों को अपडेट नहीं करते हैं,
              तो आपके वॉलेट बैलेंस पर जुर्माना लगाया जाएगा। रिजल्ट अपडेट नहीं
              करने पर 25 रुपये पेनल्टी लगेगी।
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default RoomCodeGameRules;
