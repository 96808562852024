import React, { useContext } from "react";
import "./Navbar.css";
import Topbar from "../topbar/Topbar";
import AppsIcon from "@mui/icons-material/Apps";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import navlog from "../../assets/images/logo.jpg";
import Sidebar from "../sidebar/Sidebar";
import DataContext from "../../context/DataContext";
import GuideTab from "../guide_tab/GuideTab";
import { Link } from "react-router-dom";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const Navbar = () => {
  const { state, dispatch } = useContext(DataContext);
  let user_balance = state.user_data.walletBalance;

  // console.log(user_balance);
  // console.log(context_data)
  return (
    <>
      <Topbar />
      <div className="navbar-container">
        <div className="navbar-icons">
          <div
            className="navbar-icon"
            onClick={() => {
              dispatch({ type: "CLICKED_FOR_SIDEBAR" });
            }}
          >
            <AppsIcon sx={{ fontSize: 50, color: "rgba(255,255,255,0.7)" }} />
          </div>
          <div className="navbar-icon">
            <Link to="/">
              <img
                className="navbar-icon-img"
                width="42px"
                height="42px"
                src={navlog}
                alt="kd124 logo"
              />
            </Link>
          </div>

          {!state.isAuth ? (
            <div
              className="navbar-icon"
              onClick={() => {
                dispatch({ type: "CLICKED_FOR_GUIDE" });
              }}
            >
              <HelpCenterIcon
                sx={{ fontSize: 50, color: "rgba(255,255,255,0.7)" }}
              />
            </div>
          ) : (
            <Link to="/wallet">
              <div className="navbar-icon navbar-wallet">
                <AccountBalanceWalletIcon
                  sx={{ fontSize: 15, color: "rgb(27,27,27)" }}
                />
                {user_balance}
              </div>{" "}
            </Link>
          )}
          {/* 
          {state.isAuth ? (
            <Link to="/wallet">
              <div className="navbar-icon navbar-wallet">
                <AccountBalanceWalletIcon
                  sx={{ fontSize: 15, color: "rgb(27,27,27)" }}
                />
                {user_balance}
              </div>{" "}
            </Link>
          ) : (
            ""
          )} */}

          {/* {state.isAuth ? <div className='navbar-icon navbar-wallet'>
            <AccountBalanceWalletIcon sx={{ fontSize: 30, color:"rgb(27,27,27)" }}/>5000
        </div>:""} */}
        </div>
      </div>
      {/* {state.sidebar ? <Sidebar/> : ""} */}
      <Sidebar />
      <GuideTab />
      {/* {state.guidetab ? <GuideTab/> : ""} */}
      { }
    </>
  );
};

export default Navbar;
