import React, { useContext } from "react";
import "./KycFormHeader.css";
import DataContext from "../../../../context/DataContext";
import ArrowDropDownCircleTwoToneIcon from "@mui/icons-material/ArrowDropDownCircleTwoTone";
import { Link } from "react-router-dom";

const KycFormHeader = () => {
  const { state, dispatch } = useContext(DataContext);
  return (
    <>
      <div className="kyc-header-heading">
        <Link to="/profile">
          <button className="kyc-header-back-button">Back</button>
        </Link>

        <button className="kyc-header-heading-button">
          <div
            className="kyc-header-guide-button"
            onClick={() => dispatch({ type: "CLICKED_FOR_GUIDE" })}
          >
            <ArrowDropDownCircleTwoToneIcon sx={{ color: "white" }} />
            Guide
          </div>
        </button>
      </div>
    </>
  );
};

export default KycFormHeader;
