import React from 'react'
import "./SupportIcon.css"
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Link, useNavigate } from 'react-router-dom';


const SupportIcon = () => {
    let navigate = useNavigate()
  return (
    <div className='supporticon-container' onClick={()=> navigate("")}>
      <Link to="/support"> <SupportAgentIcon  sx={{ fontSize: 40, color:"black" }}/></Link>
       
    </div>
  )
}

export default SupportIcon