import React from "react";
import formlogo from "../../../../assets/images/logo.jpg";
import "./LoginFormHeading.css";

const LoginFormHeading = () => {
  return (
    <>
      <img className="loginform-img" width="80px" src={formlogo} />
      <div className="loginform-heading">
        Login to
        <span>PLAY & EARN</span>
      </div>
    </>
  );
};

export default LoginFormHeading;
