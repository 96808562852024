import React from "react";
import "./Loading.css";
import kd_logo_loading from "../../assets/images/logo.jpg";

const Loading = () => {
  return (
    <div className="loading-container">
      <img width="100px" src={kd_logo_loading} alt="KD logo" />
      <div>Loading...</div>
    </div>
  );
};

export default Loading;
