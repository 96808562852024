import React, { useState } from "react";
import "./RunningBattleCard.css";
import moneyimg from "../../../../../assets/images/money.png";
import { Link, useLocation } from "react-router-dom";
import ViewModal from "./view_modal/ViewModal";

const RunningBattleCard = ({ runnig, user, winamountFun }) => {
  console.log("passed prob :- runnig", runnig);
  console.log("Passed prob:- user", user);
  // console.log("passed prob:- data")

  // const history = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const viewClick = () => {
    console.log("View is clicked..........++++++");
    // setShowModal(true);
    if (runnig.status == "conflict") {
      setShowModal(true);
      console.log("View other button is activated and clicked");
    } else {
      // history.push(`/viewgame1/${runnig._id}`, {
      //   prevPath: window.location.pathname,
      // });
    }
  };

  console.log("running status is :- ", runnig.status);

  return (
    <>
      {showModal && (
        <ViewModal setShowModal={setShowModal} runnig={runnig} user={user} />
      )}
      <div className="running-battle-card-container">
        <div className="running-battle-card-heading">
          <div className="running-battle-amount">
            Playing For
            <img width="22px" src={moneyimg} alt="money image" />
            <span>{runnig.gameAmount}</span>
          </div>
          {/* start */}

          {/* end */}
          {(user === runnig.acceptedBy._id &&
            runnig.status === "pending" &&
            runnig.acceptorStatus === null) ||
          (user === runnig.createdBy._id &&
            runnig.status === "pending" &&
            runnig.creatorStatus === null) ||
          (user === runnig.acceptedBy._id &&
            runnig.creatorStatus === null &&
            runnig.acceptorStatus === null) ||
          (user === runnig.createdBy._id &&
            runnig.creatorStatus === null &&
            runnig.acceptorStatus === null) ? (
            <Link
              to={{
                pathname: `/roomcode/${runnig._id}`,
                state: { prevPath: window.location.pathname },
              }}
            >
              <button className="running-bat-view-button1">View</button>
            </Link>
          ) : (user === runnig.acceptedBy._id ||
              user === runnig.createdBy._id) &&
            (runnig.status === "conflict" || runnig.status === "pending") ? (
            <button
              onClick={() => setShowModal(true)}
              className="running-bat-view-button2"
            >
              View
            </button>
          ) : null}
          <div className="running-battle-prize">
            Prize
            <img width="22px" src={moneyimg} alt="money image" />
            <span>₹{winamountFun(runnig.gameAmount)}</span>
          </div>
        </div>
        <div className="running-battle-card-line"></div>
        <div className="running-battle-users">
          <div>{runnig.createdBy.name}</div>
          <div style={{ color: "#dc3546", fontSize: "18px" }}>v/s</div>
          <div>{runnig.acceptedBy.name}</div>
        </div>
      </div>

      {/* Start */}

      {/*  End*/}
    </>
  );
};

export default RunningBattleCard;
