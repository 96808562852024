import React, { useEffect, useState } from "react";
import axiosHelper from "../../../../api's/axiosHelper";
import HistoryPaginate from "../history_paginate/HistoryPaginate";
import ShowReferralCard from "./show_referral_card/ShowReferralCard";

const ShowReferral = ({ user_id }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [data, setData] = useState([]);
  const limit = 100;

  const Allgames = async (user_id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    await axiosHelper
      .get(`referral/code/win/${user_id}?page=${pageNumber}&_limit=${limit}`, {
        headers,
      })
      .then((res) => {
        console.log("data at referrals is:- ", res);
        if (res.data.message) {
          setData([]);
          setNumberOfPages(0);
          return;
        }
        setData(res.data);
        setNumberOfPages(res.data.totalPages);
      });
  };

  useEffect(() => {
    Allgames(user_id);
  }, [pageNumber]);

  return (
    <>
      {data.length <= 0 && (
        <div style={{ color: "white" }}>No referral data found</div>
      )}
      {data &&
        data.map((val, index) => {
          return (
            <ShowReferralCard
              key={index}
              userName={val.earned_from ? val.earned_from.name : ""}
              date={val.createdAt}
              amount={val.amount}
              balance={val.closingBalance}
            />
          );
        })}
      {/* <ShowGameCard
                key={index}
                userName={userName}
                date={val.createdAt}
                amount={val.winAmount}
                balance={balance}
              /> */}
      {numberOfPages > 1 && (
        <HistoryPaginate
          currentPage={pageNumber}
          setPageNo={setPageNumber}
          totalPageNo={numberOfPages}
        />
      )}
    </>
  );
};

export default ShowReferral;
