import React, { useContext } from "react";
import HomeScreenCard from "../../components/card/HomeScreenCard";
import "./Support.css";
import support_icon from "../../assets/images/contact_us.webp";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LeftSideCard from "../../components/card/LeftSideCard";
import Rightside from "../../components/rightside/Rightside";
import BackIcon from "../../components/back_icon/BackIcon";
import Navbar from "../../components/navbar/Navbar";
import EmailIcon from "@mui/icons-material/Email";
import Leftside from "../../components/leftside/Leftside";
import DataContext from "../../context/DataContext";
import ChatIcon from "@mui/icons-material/Chat";
import InstagramIcon from "@mui/icons-material/Instagram";
import Kyc from "../../components/kycCard/KycCard";
//
const Support = () => {
  const { state } = useContext(DataContext);
  console.log("Data at support is ", state.web_settings.data.whatsapp)

  const email_button = (
    <a href="mailto:support@kd124.com" target="_blank">
      <div className="support-email support-icon">
        <EmailIcon />
        support@kd124.com
      </div>
    </a>
  );

  const whatsapp_buttons = state.web_settings.data.whatsapp.map((val, index) => {
    return (!val.active) ?
      <a key={index} className="cxy" href={`https://wa.me/+${val.number}`} target="_blank">
        <div className="support-whatsapp support-icon">
          <WhatsAppIcon />
          Connect on Whatsapp
        </div>
      </a> : "";
  })

  return (
    <>
      <Navbar />

      <Leftside>
        {(state.user_data.verified !== "verified" && state.isAuth) && <Kyc />}
        <div className="support-container">
          <img src={support_icon} />
          <div className="support-content">
            {state.isAuth ? (
              <a href="#" target="_blank">
                <div className="support-live support-icon">
                  <ChatIcon />
                  Live Chat
                </div>
              </a>
            ) : (
              ""
            )}


            {state.isAuth ? (
              <a href="#" target="_blank">
                <div className="support-instagram support-icon">
                  <InstagramIcon />
                  Connect On Instagram
                </div>
              </a>
            ) : (
              ""
            )}


            {state.isAuth ? (
              whatsapp_buttons
            ) : (
              <a href="https://wa.me/+917425924145" target="_blank">
                <div className="support-whatsapp support-icon">
                  <WhatsAppIcon />
                  Connect on Whatsapp
                </div>
              </a>
            )}



            {!state.isAuth ? <div>Or</div> : ""}

            {!state.isAuth ? (
              <a href="mailto:support@kd124.com" target="_blank">
                <div className="support-email support-icon">
                  <EmailIcon />
                  support@kd124.com
                </div>
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </Leftside>
      {/*
    <Navbar />
    <LeftSideCard>
        <div className='support-container'>
            <div className='support-content'>
                <a href="https://api.whatsapp.com/send/?phone=%2B917425924145&text&type=phone_number&app_absent=0"
                    target="_blank"><button>
                        Connect on Whatsapp</button></a>
                <div>Or</div>
                <a href='mailto:support@kd124.com' target='_blank'>
                    <div className='support_email'>support@kd124.com</div>
                </a>
            </div>
            <img src={support_icon} />

        </div>
    </LeftSideCard>
    <Rightside />
    <BackIcon /> */}

      {/*
    <Rightside /> */}
    </>
  );
};

export default Support;
