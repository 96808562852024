import React from "react";
import "./PaymentMode.css";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

const PaymentMode = ({ chipsAvilable }) => {
  return (
    <div className="withdraw-payment-mode-container">
      <div className="withdraw-payment-mode-heading">Payment Mode</div>

      <div className="withdraw-payment-chips">
        <span>Withdrawal Chips:</span>
        <span className="available-chips-span">
          <AccountBalanceWalletIcon
            sx={{ fontSize: 18, color: "rgb(221,221,221)" }}
          />
          {chipsAvilable ? chipsAvilable : 0}
        </span>
      </div>

      <div className="withdraw-payment-limits">
        <span>minimum: 200</span>
        <span>maximum: 1,00,000</span>
      </div>
    </div>
  );
};

export default PaymentMode;
