import React from "react";
import "../open_battle_card/OpenBattleCard.css";
import moneyimg from "../../../../../assets/images/money.png";
import { ToastError } from "../../../../../helper/tost_helper/ToastError";

const OpenBattleStaticCard = ({ data, staticClick }) => {
  function clickHandler() {
    staticClick(data.key);
    ToastError("Some error occoured. Please try again later");
  }
  return (
    <>
      <div className="open-battle-card-container">
        <div className="open-battle-card-heading">
          <div>
            Challenge From{" "}
            <span className="open-battle-card-heading-span">
              {data.userName}
            </span>
          </div>
        </div>

        <div className="open-battle-card-line"></div>
        <div className="open-battle-card-details">
          <div className="open-battle-entry-details">
            <div>Enrty Fee</div>
            <div className="open-battle-entry-amount">
              <img width="22px" src={moneyimg} alt="money image" />
              <span> {data.entryFee} </span>
            </div>
          </div>

          <button className="open-battle-play-button" onClick={clickHandler}>
            Play
          </button>

          <div className="open-battle-prize-details">
            <div>Prize</div>
            <div className="open-battle-entry-amount">
              <img width="22px" src={moneyimg} alt="money image" />
              <span> {data.prize} </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpenBattleStaticCard;
