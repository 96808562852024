import React from "react";
import "./History.css";
import Navbar from "../../components/navbar/Navbar";
import HistoryTab from "./history_tab/HistoryTab";
import Leftside from "../../components/leftside/Leftside";
import { HistoryDataProvider } from "../../context/HistoryDataProvider";

const History = () => {
  return (
    <>
      <Navbar />
      <Leftside>
        <HistoryDataProvider>
          <HistoryTab />
        </HistoryDataProvider>
      </Leftside>
    </>
  );
};

export default History;
