import React, { useContext } from "react";
import "./Wallet.css";
import Leftside from "../../components/leftside/Leftside";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import WalletIcon from "@mui/icons-material/Wallet";
import Navbar from "../../components/navbar/Navbar";
import DataContext from "../../context/DataContext";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const Wallet = () => {
  const { state } = useContext(DataContext);
  const navigate = useNavigate();

  let deposite_chips = state.user_data.addChip;
  let withdraw_chips = state.user_data.withdrawAmount;

  return (
    <>
      <Navbar />
      <Leftside>
        <div className="wallet-container">
          <div className="wallet-buttons">
            <button
              className="wallet-back-button"
              onClick={() => {
                navigate("/");
              }}
            >
              <KeyboardDoubleArrowLeftIcon />
              Back
            </button>
            <Link to="/history">
              <button className="wallet-history">
                {/* <WalletIcon/> */}
                Wallet History
              </button>
            </Link>
          </div>
          <div className="wallet-content">
            <div className="wallet-depositechips">
              <div className="depositechips-heading">Deposite Chips</div>
              <p className="depositechips-message">
                यह चिप्स Win एवं Buy की गई चिप्स है इनसे सिर्फ़ गेम खेले जा सकते
                है ॥ Bank या UPI में निकाला नहीं जा सकता है
              </p>
              <div className="depositechips-amount">
                <div>Chips</div>
                <span>{deposite_chips}</span>
              </div>
              <Link to="/addcash">
                <button className="depositechips-addbtn">Add</button>
              </Link>
            </div>
            <div className="wallet-winningchips">
              <div className="winningchips-heading">Winning Chips</div>
              <p className="winningchips-message">
                यह चिप्स गेम से जीती हुई एवं रेफरल से कमाई हुई है इन्हें Bank या
                UPI में निकाला जा सकता है ॥ इन चिप्स से गेम भी खेला जा सकता है
              </p>
              <div className="winningchips-amount">
                <div>Chips</div>
                <span>{withdraw_chips}</span>
              </div>
              <Link to="/withdraw">
                <button className="winningchips-withdrawbtn">Withdraw</button>
              </Link>
            </div>
          </div>
        </div>
      </Leftside>
    </>
  );
};

export default Wallet;
