import React, { useReducer } from "react";
import DataContext from "./DataContext";

const reducerFun = (state, action) => {
  console.log(action);
  console.log(state);
  if (action.type === "CLICKED_FOR_SIDEBAR") {
    return { ...state, sidebar: !state.sidebar, guidetab: false };
  } else if (action.type === "CLICKED_FOR_GUIDE") {
    return { ...state, guidetab: !state.guidetab, sidebar: false };
  } else if (action.type === "CLICKED_FOR_RULES") {
    return { ...state, rulesmodal: !state.rulesmodal };
  }
  else if (action.type === "AUTH_SUCCESS") {
    return { ...state, isAuth: true };
  } else if (action.type === "USER_DATA") {
    return { ...state, user_data: { ...action.data } };
  } else if (action.type === "WEBSITE_SETTING") {
    return { ...state, web_settings: { ...action.data } };
  }
  return state;
};

const DataProvider = ({ children }) => {
  const initialSate = {
    sidebar: false,
    guidetab: false,
    isAuth: false,
    rulesmodal: false,
    user_data: {},
    web_settings: {},
  };

  const [state, dispatch] = useReducer(reducerFun, initialSate);
  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
