import React, { useState } from "react";
// import "./ShowAll.css";
import TransactionHistoryCard from "../transaction_history_card/TransactionHistoryCard";

const ShowAll = ({ data }) => {
  console.log("Data at all show is :- ", data);
  // const [mapHistoryData, setMapHistoryData] = useState(data);

  return (
    <>
      {!data && <div style={{ color: "white" }}>No Data Found</div>}
      {data
        ?.filter((val) => val.status !== "FAILED")
        .map((val) => {
          // console.log("Filtered data for all games:- ", val);
          if (val?.remark === "I Win") {
            return { ...val, reqType: "YOU WON IT", status: "Match Won" };
          } else if (val.remark === "I Lose") {
            return { ...val, reqType: "YOU LOST IT", status: "Match Lost" };
          }
          return val;
        })
        .map((val, index) => {
          return (
            <TransactionHistoryCard
              key={index}
              title={val.reqType}
              status={val.status}
              date={val.createdAt}
              amount={val.amount}
              // balance={val.closingBalance}
            />
          );
        })}
    </>
  );
};

export default ShowAll;
