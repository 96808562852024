import React, { useContext } from "react";
import "./ButtonBar.css";
import HistoryContext from "../../../../context/HistoryDataProvider";

const ButtonBar = ({ historyDispatch }) => {
  // const { state, historyDispatch } = useContext(HistoryContext);
  // console.log("History context data is :- ", state);

  return (
    <>
      <div className="history-tab-container">
        <div className="history-button-container">
          <div className="history-button">
            <button
              onClick={() => {
                historyDispatch({ type: "SHOW_ALL" });
              }}
            >
              All
            </button>
          </div>

          <div className="history-button">
            <button
              onClick={() => {
                historyDispatch({ type: "SHOW_GAME" });
              }}
            >
              Game
            </button>
          </div>

          <div className="history-button">
            <button
              onClick={() => {
                historyDispatch({ type: "SHOW_WITHDRAW" });
              }}
            >
              Withdraw
            </button>
          </div>

          <div className="history-button">
            <button
              onClick={() => {
                historyDispatch({ type: "SHOW_DEPOSIT" });
              }}
            >
              Deposit
            </button>
          </div>

          <div className="history-button">
            <button
              onClick={() => {
                historyDispatch({ type: "SHOW_REFERRAL" });
              }}
            >
              Referral
            </button>
          </div>

          <div className="history-button">
            <button
              onClick={() => {
                historyDispatch({ type: "SHOW_BONUS" });
              }}
            >
              Bonus
            </button>
          </div>

          <div className="history-button">
            <button
              onClick={() => {
                historyDispatch({ type: "SHOW_PENALTY" });
              }}
            >
              Penalty
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ButtonBar;
