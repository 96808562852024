import React, { useContext, useEffect, useRef, useState } from "react";
import "./GameData.css";
import OpenBattle from "./open_battle/OpenBattle";
import RunningBattle from "./running_battle/RunningBattle";
import BatAmountBar from "./bat_amount_bar/BatAmountBar";
import { useLocation, useParams } from "react-router-dom";
import axiosHelper from "../../../api's/axiosHelper";
import { ToastError } from "../../../helper/tost_helper/ToastError";
import DataContext from "../../../context/DataContext";

let STATIC_BET_AMOUNTS = [
  50, 50, 350, 100, 3000, 50, 150, 900, 1500, 50, 100, 250, 500, 100, 50, 2500,
  700, 50, 350, 1100, 650, 100, 50, 5000, 450, 250, 600, 1050, 1500, 100, 150,
  350, 50, 550, 1100, 1200, 150, 1600, 450, 50, 50, 2000, 300, 2500, 100, 750,
  350, 3000, 50, 1650, 850, 400, 500, 600, 50, 200, 1350, 100, 300, 950,
];

const threshold = 25000;

const belowThreshold = STATIC_BET_AMOUNTS.filter(
  (amount) => amount < threshold
);
const aboveThreshold = STATIC_BET_AMOUNTS.filter(
  (amount) => amount >= threshold
);

const GameData = () => {
  let { state, dispatch } = useContext(DataContext);
  // console.log("user id is :-", state.user_data._id);
  let userID = state.user_data._id;
  const isMounted = useRef(true);
  const websocketURL = process.env.REACT_APP_SOCKET_API;
  const [isMode, setMode] = useState(
    localStorage.getItem("Mode") || "liteMode"
  );

  STATIC_BET_AMOUNTS = isMode === "liteMode" ? belowThreshold : aboveThreshold;

  const [user, setUser] = useState(state.user_data._id);
  const [created, setCreated] = useState([]);
  const [socket, setSocket] = useState();
  const [staticBets, setStaticBets] = useState([]);
  const [staticRunningBets, setRunningStaticBets] = useState([]);
  const [userAllData, setUserAllData] = useState(state.user_data);
  const [randomName, setRandomName] = useState();
  // console.log("website setting data:-", state.web_settings);
  const [WebSitesettings, setWebsiteSettings] = useState(state.web_settings);
  const [game_type, setGame_type] = useState(
    useLocation().pathname.split("/")[2]
    // useParams().game
  );

  // console.log("useparam data is :-", test);

  // const [game_type, setGame_type] = useState("Ludo Classics");
  // console.log("game type is:- ", game_type);
  // console.log("generated static bets:- ", staticBets);
  // console.log("generated running bets:- ", staticRunningBets);

  useEffect(() => {
    setStaticBets(generateRandomBetSet(5));
    setRunningStaticBets(generateRandomBetSet(5));

    const interval = setInterval(() => {
      setStaticBets((prevStaticBets) => {
        const randomAmountIndex = Math.floor(
          Math.random() * STATIC_BET_AMOUNTS.length
        );

        // Decide whether to add or remove a card
        const addCard = Math.random() < 0.5; // 50% chance to add a card

        if (addCard) {
          // Add a new random bet to the beginning of the array
          return [
            generateRandomBet(STATIC_BET_AMOUNTS[randomAmountIndex]),
            ...prevStaticBets.slice(0, 5), // Slice to keep only 9 cards
          ];
        } else {
          // Remove the last bet from the array, if it's not empty
          if (prevStaticBets.length > 0) {
            return prevStaticBets.slice(0, 5); // Slice to keep only 9 cards
          } else {
            return prevStaticBets; // Return the unchanged state
          }
        }
      });

      setRunningStaticBets((prevRunningStaticBets) => {
        const randomAmountIndex = Math.floor(
          Math.random() * STATIC_BET_AMOUNTS.length
        );

        // Decide whether to add or remove a card
        const addCard = Math.random() < 0.5; // 50% chance to add a card

        if (addCard) {
          // Add a new random bet to the beginning of the array
          return [
            generateRandomBet(STATIC_BET_AMOUNTS[randomAmountIndex]),
            ...prevRunningStaticBets.slice(0, 5), // Slice to keep only 9 cards
          ];
        } else {
          // Remove the last bet from the array, if it's not empty
          if (prevRunningStaticBets.length > 0) {
            return prevRunningStaticBets.slice(0, 5); // Slice to keep only 9 cards
          } else {
            console.log("static bet length is zero");
            return prevRunningStaticBets; // Return the unchanged state
          }
        }
      });
    }, 1500);
    return () => clearInterval(interval);
  }, [randomName]);

  //function to generate random bets
  function generateRandomBetSet(setLength) {
    const amounts = generateSubArray(STATIC_BET_AMOUNTS, setLength);
    const bets = amounts.map((amount) => {
      return generateRandomBet(amount);
    });
    return bets;
  }

  function generateSubArray(mainArray, subArrayLength) {
    // Check if the main array is shorter than the desired subarray length
    if (mainArray.length < subArrayLength) {
      throw new Error("Main array length is shorter than subarray length.");
    }

    // Generate random start index within valid range
    const startIndex = Math.floor(
      Math.random() * (mainArray.length - subArrayLength + 1)
    );

    // Calculate end index within valid range
    const endIndex = startIndex + subArrayLength;

    // Generate the subarray
    const subArray = mainArray.slice(startIndex, endIndex);

    return subArray;
  }

  function generateRandomBet(amount) {
    const winAmount = winamountFun(amount);
    return { amount, winAmount, playerName: generateRandomString(6) };
  }

  function winamountFun(gameAmount) {
    let profit = null;
    if (gameAmount >= 50 && gameAmount <= 400) {
      profit =
        (gameAmount * parseInt(WebSitesettings?.adminPercentage50To400 || 5)) /
        100;
    } else if (gameAmount > 400) {
      profit =
        (gameAmount * parseInt(WebSitesettings?.adminPercentage400To || 5)) /
        100;
    }
    return gameAmount * 2 - profit;
  }

  function generateRandomString(length) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // function and values for allgames

  const [allgame, setallgame] = useState([]);
  const [mount, setMount] = useState(false);
  const [runningGames, setRunningGames] = useState();
  const [ownRunning, setOwnRunning] = useState([]);

  const Allgames = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    await axiosHelper
      .get(`challange/all`, { headers })
      .then((res) => {
        if (res.data.message == "Game challenges not found") {
          return;
        }
        let owenedCreated = [],
          remainingGame = [];
        res.data.forEach(function (ele) {
          if (
            ele.createdBy._id === user &&
            (ele.status === "new" || ele.status === "requested")
          ) {
            owenedCreated.push(ele);
          } else {
            remainingGame.push(ele);
          }
        });
        console.log("allGames API response data", res.data);
        console.log("allgames data to created useSteate", owenedCreated);
        console.log("allagems data to allgame useState", remainingGame);
        setCreated(owenedCreated);
        setallgame(remainingGame);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        } else if (e.response.status === 400 || e.response.status === 429) {
          ToastError("Please refresh!");
        }
      });
  };

  const runningGame = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axiosHelper
      .get(`challange/running/all`, { headers })
      .then((res) => {
        if (res.data.message == "Game challenges not found") {
          return;
        }
        let owenedRunning = [],
          remainingRunning = [];
        res.data.forEach(function (ele) {
          if (ele.createdBy && ele.acceptedBy)
            if (
              ele.createdBy._id === userID.current ||
              ele.acceptedBy._id === userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
        if (e.response.status === 400 || e.response.status === 429) {
          ToastError("Please refresh!");
        }
      });
  };

  const fetchRandomName = async () => {
    let data = await axiosHelper.get("randomUserName");
    console.log("Fetched random name is:-", data);
    return setRandomName(data);
  };

  async function walletUpdate() {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      let user_response = await axiosHelper.get(`me`, { headers });
      if (user_response) {
        dispatch({ type: "USER_DATA", data: user_response.data });
      }
    } catch (e) {
      console.log("wallet update error...");
    }
  }

  useEffect(() => {
    fetchRandomName();
  }, []);

  //websocket effect started
  useEffect(() => {
    WebSocket.prototype.emit = function (event, data) {
      if (this.readyState === WebSocket.OPEN)
        this.send(JSON.stringify({ event, data }));
    };

    WebSocket.prototype.listen = function (eventName, callback) {
      this._socketListeners = this._socketListeners || {};
      this._socketListeners[eventName] = callback;
    };

    let socket = new WebSocket(websocketURL);

    function openFunc() {
      socket.onopen = () => {
        console.log("websocket is connected 👍");
        setSocket(socket);
        socket.pingTimeout = setTimeout(() => {
          socket.close();
          setSocket(undefined);
        }, 30000 + 1000);
      };
    }

    function listenFunc() {
      socket.onmessage = function (e) {
        try {
          const { event, data } = JSON.parse(e.data);
          socket._socketListeners[event](data);
        } catch (error) {
          console.log(error);
        }
      };

      socket.listen("ping", (data) => {
        socket.emit("pong", 2);
        clearTimeout(socket.pingTimeout);
        socket.pingTimeout = setTimeout(() => {
          console.log("ping terminate works 🏩");
          socket.close();
          setSocket(undefined);
        }, 30000 + 1000);
      });

      socket.listen("recieveGame", (data) => {
        console.log(
          "Receivedddddd datattttt of triggggeeerrreed websocketttttt"
        );
        console.log("receiveGameData", data);
        if (data?.msg) {
          if (data.userId === userID) {
            ToastError(data.msg, 2000);
          }
          return;
        }
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id === userID.current &&
              (ele.status === "new" || ele.status === "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        // console.log('own',owenedCreated,'remiain',remainingGame);
        console.log("ownedCreated", owenedCreated);
        console.log("remainingGame", remainingGame);
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });

      socket.listen("updateRunning", (data) => {
        console.log("updateRunning websocketttt received data ", data);
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        console.log("ownedCreated", owenedCreated);
        console.log("remainingGame", remainingGame);
        setCreated(owenedCreated);
        setallgame(remainingGame);
        walletUpdate();
      });

      socket.listen("acceptor_seen", (data) => {
        console.log("Acceptor seen websocketttt received data ", data);
        let owenedCreated = [],
          remainingGame = [];
        data.openBattle.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        let owenedRunning = [],
          remainingRunning = [];
        data.runningBattle.forEach(function (ele) {
          if (ele.createdBy && ele.acceptedBy)
            if (
              ele.createdBy._id == userID.current ||
              ele.acceptedBy._id == userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        console.log("owenedRunning", owenedRunning);
        console.log("remainingRunning", remainingRunning);
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
        walletUpdate();
      });

      socket.listen("resultUpdateReq", (data) => {
        let owenedRunning = [],
          remainingRunning = [];
        data.forEach(function (ele) {
          if (ele.createdBy && ele.acceptedBy)
            if (
              ele.createdBy._id == userID.current ||
              ele.acceptedBy._id == userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
        walletUpdate();
      });

      socket.listen("startAcepptor", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        walletUpdate();
      });

      socket.listen("challengeAccepted", (data) => {
        console.log("challengeAccepted socket received data:-----", data);
        if (data?.msg) {
          if (data?.userId === userID) {
            ToastError(data.msg);
          }
        }
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        console.log("owenedCreated", owenedCreated);
        console.log("remainingGame", remainingGame);
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });

      socket.listen("updateReject", (data) => {
        console.log("updateReject socket received data:-----", data);
        if (data?.msg) {
          if (data.userId === userID) {
            ToastError(data.msg);
          }
        }
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });

        console.log("owenedCreated", owenedCreated);
        console.log("remainingGame", remainingGame);
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });

      socket.listen("ongoingChallenge", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.openBattle.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        let owenedRunning = [],
          remainingRunning = [];
        data.runningBattle.forEach(function (ele) {
          if (ele.createdBy && ele.acceptedBy)
            if (
              ele.createdBy._id == userID.current ||
              ele.acceptedBy._id == userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
      });

      socket.listen("updateDelete", (data) => {
        console.log("Deleteddddd webSocket is triggered and the data is", data);
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });

        console.log("ownedCreated", owenedCreated);
        console.log("remainingGame", remainingGame);
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });
    }
    //listen fun end

    function closeFunc() {
      socket.onclose = () => {
        console.log("socket disconnected wow 😡");

        if (isMounted.current) {
          clearTimeout(socket.pingTimeout);
          setSocket(undefined);

          let socket = new WebSocket(websocketURL);
          openFunc();
          listenFunc();
          closeFunc();
        }
      };
    }
    openFunc();
    listenFunc();
    closeFunc();

    return () => {
      isMounted.current = false;
      clearTimeout(socket.pingTimeout);
      setSocket(undefined);
      socket.close();
    };
  }, []);
  //websocket effect ended

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axiosHelper
      .get(`me`, { headers })
      .then((res) => {
        setUser(res.data._id);
        setUserAllData(res.data);
        // userID.current = res.data._id;
        setMount(true);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
        if (e.response.status === 400 || e.response.status === 429) {
          ToastError("Please refresh!");
        }
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      setTimeout(() => {
        //  history.push("/login")
      }, 500);
    }
    role();
    if (mount) {
      Allgames();
      runningGame();
    }
  }, [mount]);

  //accept chalange function
  const AcceptChallang = async (id) => {
    console.log(
      "Accepted game api calllllledddd........................accept game"
    );
    let ws_data = {
      userId: userID,
      gameId: id,
    };

    socket.emit("acceptGame", ws_data);

    // socket.emit("acceptGame", ws_data);

    // const access_token = localStorage.getItem("token");
    // const headers = {
    //   Authorization: `Bearer ${access_token}`,
    // };
    // axiosHelper
    //   .put(
    //     `challange/accept/${id}`,
    //     {
    //       acceptedBy: headers,
    //       acceptorByCreatorAt: Date.now(),
    //     },
    //     {
    //       headers,
    //     }
    //   )
    //   .then((res) => {
    //     console.log(res);
    //     if (res.data.msg === "you have already enrolled") {
    //       ToastError("You have already enrolled");
    //     }
    //     if (res.data.msg === "Insufficient balance") {
    //       ToastError("Insufficient balance");
    //     } else {
    //       // Allgames(res.data);
    //       console.log("Socket triggered........");

    //       socket.emit("acceptGame", ws_data);
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     if (e.response.status == 401) {
    //       localStorage.removeItem("token");
    //       localStorage.removeItem("token");
    //       window.location.reload();
    //       setTimeout(() => {
    //         //  history.push("/login")
    //       }, 500);
    //     }
    //     if (e.response.status == 400 || e.response.status == 429) {
    //       ToastError("Please refresh!");
    //     }
    //   });
  };

  //reject Game
  const RejectGame = (id) => {
    console.log("Reject game is api callleedddddd......,.");

    let ws_data = {
      userId: userID,
      gameId: id,
    };

    socket.emit("gameRejected", ws_data);

    // const access_token = localStorage.getItem("token");
    // const headers = {
    //   Authorization: `Bearer ${access_token}`,
    // };

    // axiosHelper
    //   .put(
    //     `challange/reject/${id}`,
    //     {
    //       acceptedBy: null,
    //       status: "new",
    //       acceptorByCreatorAt: null,
    //     },
    //     { headers }
    //   )
    //   .then((res) => {
    //     // Allgames();
    //     console.log(res);
    //     console.log("gameRejected socket is triggered.........");
    //     socket.emit("gameRejected");
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     if (e.response.status == 401) {
    //       localStorage.removeItem("token");
    //       localStorage.removeItem("token");
    //       window.location.reload();
    //       setTimeout(() => {
    //         //  history.push("/login")
    //       }, 500);
    //     }
    //     if (e.response.status == 400 || e.response.status == 429) {
    //       ToastError("Please refresh!");
    //     }
    //   });
  };

  //delete
  const deleteChallenge = (_id) => {
    console.log("Deleted api is calleddddddddddddddddddddddddddd.....");
    socket.emit("deleteGame", _id);

    // const access_token = localStorage.getItem("token");
    // const headers = {
    //   Authorization: `Bearer ${access_token}`,
    // };

    // axiosHelper
    //   .delete(`challange/delete/${_id}`, { headers })
    //   .then((res) => {
    //     console.log("deleted api res data :-", res);
    //     socket.emit("deleteGame", _id);
    //   })
    //   .catch((e) => {
    //     if (e.response.status == 401) {
    //       localStorage.removeItem("token");
    //       localStorage.removeItem("token");
    //       window.location.reload();
    //     }
    //     if (e.response.status == 400 || e.response.status == 429) {
    //       ToastError("Please refresh!");
    //     }
    //   });
  };

  ///challange/running/update/

  const updateChallenge = (_id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axiosHelper
      .put(
        `challange/running/update/${_id}`,
        {
          acceptorSeen: true,
        },
        { headers }
      )
      .then((res) => {
        socket.emit("game_seen");
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status == 400 || e.response.status == 429) {
          ToastError("Please refresh!");
        }
        console.log(e);
      });
  };

  const getPost = async (Id) => {
    console.log("getpost is triggered...");
    console.log("game type is :-", game_type);
    if (
      game_type === "Ludo%20Classics" ||
      game_type === "Ludo 1 Goti" ||
      game_type === "Ludo Ulta"
    ) {
      console.log("getpost is triggered...roomCode triggered");
      socket.emit("roomCode", { game_id: Id, status: "running" });
    } else if (game_type === "Ludo Popular") {
      console.log("getpost is triggered...popular room code triggered...");
      socket.emit("popularroomCode", { game_id: Id, status: "running" });
    }
  };

  const [postsecond, setPostsecond] = useState([]);

  useEffect(() => {
    axiosHelper.get("getmessage2").then((response) => {
      setPostsecond(response.data);
    });
  }, []);

  useEffect(() => {
    if (randomName && randomName.length > 0) {
      randomName.forEach((item) => {
        setStaticBets(generateRandomBetSet(10));
      });
    }
  }, [randomName]);

  function staticChangeHandler(index) {
    console.log("Static change handler is clicked...");
    setStaticBets((currentState) => {
      const copyArray = JSON.parse(JSON.stringify(currentState));
      copyArray.splice(index, 1);
      return copyArray;
    });
  }
  // console.log("own running battle:- ", ownRunning);
  // console.log("only running bats:-", runningGames);
  return (
    <div className="game-data-container">
      <BatAmountBar
        isMode={isMode}
        socket={socket}
        //  Allgames={Allgames}
      />
      <OpenBattle
        data={staticBets}
        created={created}
        allgame={allgame}
        user={user}
        game_type={game_type}
        deleteChallenge={deleteChallenge}
        getPost={getPost}
        RejectGame={RejectGame}
        winamountFun={winamountFun}
        AcceptChallang={AcceptChallang}
        updateChallenge={updateChallenge}
        staticClick={staticChangeHandler}
      />
      <RunningBattle
        user={user}
        game_type={game_type}
        ownRunning={ownRunning}
        runningGames={runningGames}
        staticRunningBets={staticRunningBets}
        winamountFun={winamountFun}
        generateRandomString={generateRandomString}
      />
    </div>
  );
};

export default GameData;
