import React, { useEffect, useState } from "react";
import "./ProfileBottom.css";
import ProfileCard from "./ProfileCard";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SwitchAccountTwoToneIcon from "@mui/icons-material/SwitchAccountTwoTone";
import AnnouncementTwoToneIcon from "@mui/icons-material/AnnouncementTwoTone";
import axiosHelper from "../../api's/axiosHelper";

const ProfileBottom = ({ state }) => {
  // let games_played = state.user_data;
  let chips_won = state.user_data.wonAmount;
  let referral_earning = state.user_data.referralEarning;
  let penality = state.user_data;

  const [totalGame, setTotalGame] = useState(0);
  const TotalGameFun = (Id) => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axiosHelper
      .get(`total/user/all/${Id}`, { headers })
      .then((res) => {
        if (res.data.message) {
          setTotalGame(0);
        } else {
          console.log("Game played data is :- ", res.data);
          setTotalGame(res.data);
        }
      })
      .catch((e) => {
        console.log(e)
      });
  };

  useEffect(() => {
    TotalGameFun(state.user_data._id)
  }, [])
  return (
    <>
      <ProfileCard>
        <div className="profile-bottom-heading">Metrics</div>

        <div className="profile-bottom-content">
          <div className="games-played bottom-icon">
            <div>
              <SportsEsportsIcon /> Games Played{" "}
            </div>
            <span>{totalGame && totalGame}</span>
          </div>
          <div className="chips-won bottom-icon">
            <div>
              <MonetizationOnIcon sx={{ fontSize: 20 }} /> Chips Won{" "}
            </div>
            <span>{chips_won}</span>
          </div>
          <div className=" bottom-icon">
            <div>
              {" "}
              <SwitchAccountTwoToneIcon sx={{ fontSize: 20 }} /> Referral
              Earning{" "}
            </div>{" "}
            <span>{referral_earning}</span>
          </div>
          <div className="penality bottom-icon">
            <div>
              <AnnouncementTwoToneIcon sx={{ fontSize: 20 }} /> Penalty{" "}
            </div>
            <span>0</span>
          </div>
        </div>
      </ProfileCard>
    </>
  );
};

export default ProfileBottom;
