import React, { useEffect, useState } from "react";
import TransactionHistoryCard from "../transaction_history_card/TransactionHistoryCard";

const ShowPenalty = ({ data }) => {
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    let tempData = data?.filter((val) => {
      return val.reqType === "penalty";
    });
    setFilteredData(tempData);
  }, [data]);

  return (
    <>
      {" "}
      {filteredData.length <= 0 && (
        <div style={{ color: "white" }}>No penality data on this page</div>
      )}
      {filteredData.map((val, index) => {
        return (
          <TransactionHistoryCard
            key={index}
            title={val.reqType}
            status={val.status}
            date={val.createdAt}
            amount={val.amount}
            // balance={val.closingBalance}
          />
        );
      })}
    </>
  );
};

export default ShowPenalty;
