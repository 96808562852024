import React from "react";
import "./ViewModal.css";
import userIcon from "../../../../../../assets/images/avtar/user.png";
import vsImg from "../../../../../../assets/images/versus1.png";
import { Link } from "react-router-dom";

const ViewModal = ({ setShowModal, runnig, user }) => {
  return (
    <>
      <div className="view-modal-container">
        <button
          className="view-modal-close-button"
          onClick={() => setShowModal((prev) => !prev)}
        >
          X
        </button>
        <div className="view-modal-heading">
          <div className="view-modal-room-code">
            Room Code: <span>{runnig?.roomCode}</span>
          </div>
          <div className="view-modal-result">
            Your Result:{" "}
            {user === runnig?.createdBy?._id && (
              <span> {runnig?.creatorStatus}</span>
            )}
            {user === runnig?.acceptedBy?._id && (
              <span> {runnig?.acceptorStatus}</span>
            )}
          </div>
        </div>

        <div className="modal-user-card">
          <div className="modal-user1">
            <img width="50px" src={userIcon} alt="" />
            <span> {runnig?.createdBy?.name}</span>
          </div>
          <div className="modal-vs-amount">
            <img width="50px" src={vsImg} alt="" />{" "}
            <span>
              ₹<span> {runnig?.gameAmount}</span>
            </span>
          </div>

          <div className="modal-user2">
            <img width="50px" src={userIcon} alt="" />
            <span>{runnig?.acceptedBy?.name}</span>
          </div>
        </div>

        <div className="view-modal-text">
          <div>
            Your Game Result is Successfully Posted. Please allow us 2-5 minutes
            to review & update your game. If you have Posted Wrong Result or
            Screenshot, Kindly
          </div>
          <Link
            to="/support"
            // onClick={handleContactSupportClick}
          >
            Contact Support
          </Link>
        </div>
      </div>
    </>
  );
};

export default ViewModal;
