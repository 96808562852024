import React, { useContext, useEffect, useState } from "react";
import "./ReferNEarn.css";
import Leftside from "../../components/leftside/Leftside";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import referralImg from "../../assets/images/refer1.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import Navbar from "../../components/navbar/Navbar";
import DataContext from "../../context/DataContext";
import axiosHelper from "../../api's/axiosHelper";


const ReferNEarn = () => {
  const { state } = useContext(DataContext);
  let referral_code = state?.user_data?.referralCode;

  console.log("user data at referand earn", state?.user_data)

  const handleCopyToClipboard = () => {
    const textToCopy = `Play Ludo and earn *₹10000* daily. *5% commission for all game (referral bonus 2%)* . https://kd124.com/login/${referral_code} Register Now, My refer code is ${referral_code}.`

    navigator.clipboard.writeText(textToCopy);
    let selected_div = document.getElementById("copy-to-clipboard");
    selected_div.style.background = "goldenrod";
    selected_div.innerHTML = "Copied!!!"
    setTimeout(() => {

      selected_div.style.background = "rgb(118, 125, 128)";
      selected_div.innerHTML = "Copy To Clipboard"
    }, 3000)
  }

  const [totalReferred, SetTotalReferred] = useState([]);

  const AllReferred = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axiosHelper
      .get(`referral/code/${id}`, { headers })
      .then((res) => {
        // console.log("refer and earn message", res.data.message)
        if (res.data.message == "User data count not found.") {
          return SetTotalReferred(0);
        }
        SetTotalReferred(res?.data);
        // console.log("Refer and earn total game played..", res?.data);
      });
  };

  useEffect(() => {
    AllReferred(state?.user_data?._id);
  }, [])


  return (
    <>
      <Navbar />
      <Leftside>
        <div className="refer-container">
          <div className="referral-data-container">
            <div className="referral-data-heading">Your Referral Earnings</div>
            <div className="referred--players-data">
              <div className="referred-players">
                <span>Referred Players</span>
                <span>{totalReferred}</span>
              </div>
              <hr />
              <div className="referral-earning">
                <span>Referral Earning</span>
                <span>
                  <CurrencyRupeeIcon sx={{ fontSize: 18 }} />
                  {state?.user_data.referralEarning}
                </span>
              </div>
            </div>
          </div>

          <div className="referral-code">
            <div className="referral-code-heading">Referral Code</div>
            <img width="250px" src={referralImg} alt="referral img" />
            <div className="referral-code-options">
              <div className="option-input">
                <input type="text" value={referral_code} readOnly disabled />
                <button
                  id="copy_referral_btn"
                  // start

                  onClick={() => {
                    const button = document.getElementById("copy_referral_btn");

                    navigator.clipboard.writeText(referral_code);
                    button.style.backgroundColor = "goldenrod";
                    // setIsCopy(true);
                    button.innerHTML = "Copied"

                    setTimeout(() => {
                      button.style.backgroundColor = "#0d6efd";
                      button.innerHTML = "COPY"
                    }, 3000);
                  }}
                // end

                >COPY</button>
              </div>
              <span>Or</span>
              {/* <a href="#"> */}
              <a href={`whatsapp://send?text=Play Ludo and earn *₹10000* daily. *5% commission for all game (referral bonus 2%)* . https://kd124.com/login/${referral_code} Register Now, My refer code is ${referral_code}.`} >
                <div className="code-share-whatsapp code-share">
                  <WhatsAppIcon />
                  Share on Whatsapp
                </div>
              </a>

              {/* <a href="#"> */}
              <div className="code-share-link code-share"
                id="copy-to-clipboard" onClick={handleCopyToClipboard}>
                <PendingActionsIcon />
                Copy To Clipboard
              </div>
              {/* </a> */}
            </div>
          </div>
          <div className="referral-howtowork">
            <div className="referral-howtowork-heading">How It Works</div>
            <div className="referral-howtowork-content">
              <div>
                You can refer and <span>Earn 1%</span> of your referral winning,
                every time
              </div>
              <div>
                Like if your player plays for <span>₹10000</span> and wins, You
                will get <span>₹100</span> as referral amount
              </div>
            </div>
          </div>
        </div>
      </Leftside>
    </>
  );
};

export default ReferNEarn;
