import React from "react";
import "./ProfileLogout.css";
import axiosHelper from "../../api's/axiosHelper";
import { ToastError } from "../../helper/tost_helper/ToastError";
// import ProfileCard from './ProfileCard'

const ProfileLogout = () => {

  const logoutHandler = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axiosHelper
      .post(`logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        localStorage.removeItem("token");
        window.location.href = "/login";
        window.location.reload();
      })
      .catch((e) => {
        // alert(e.msg)
        localStorage.removeItem("token");
        ToastError(e.msg);
      });
  };

  return (
    // <ProfileCard>
    <button
      className="logout-button"
      onClick={logoutHandler}
    >
      Logout
    </button>
    /* </ProfileCard> */
  );
};

export default ProfileLogout;
