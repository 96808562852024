import React from "react";
import "./RoomCodeMiddle.css";
import { ToastError } from "../../../../helper/tost_helper/ToastError";
import creatorAudio from "../../../../assets/audio/creator_audio.mp3";
import acceptorAudio from "../../../../assets/audio/acceptor_audio.mp3";
import loading_gif from "../../../../assets/images/gif/loading_old.gif";
import loading_gif_white from "../../../../assets/images/gif/loading_white.gif";

import loading_color from "../../../../assets/images/gif/loading-color.webp";
const RoomCodeMiddle = ({
  Game,
  user,
  roomcode,
  setRoomcode,
  loading,
  getPost,
}) => {
  return (
    <div className="room-middle-container">
      {(Game?.roomCode == null && (
        <div className="room-code-no-user">
          {/* <div className="room-code-middle-heading">
            Waiting for Room Code...
            <h6>रूम कोड का इंतजार है।</h6>
          </div> */}
          <div>
            <img
              style={{ borderRadius: "50%" }}
              width="100px"
              src={loading_gif_white}
            />
          </div>
          {/* <div className="room-code-marquee"> */}
          {/* <marquee>
              कृपया लूडो किंग से रुम कोड निकल कर SET ROOM CODE मे डाल दे
            </marquee> */}
          {/* </div> */}
        </div>
      )) ||
        (Game?.roomCode != 0 && (
          <div>
            <div className="room-code-success">
              <p>Room Code</p>

              <span>{Game.roomCode}</span>
              <button
                id="roomCodeCopy"
                onClick={(e) => {
                  navigator.clipboard.writeText(Game.roomCode);
                  let selectBtn = document.getElementById("roomCodeCopy");
                  selectBtn.style.background = "goldenrod";
                  selectBtn.style.color = "black";
                  selectBtn.innerHTML = "COPIED!!!";
                  setTimeout(() => {
                    selectBtn.innerHTML = "COPY CODE";
                    selectBtn.style.background = "rgb(11, 112, 107)";
                    selectBtn.style.color = "rgb(221,221,221)";
                  }, 3000);
                }}
              >
                COPY CODE
              </button>
            </div>
          </div>
        )) ||
        (Game?.roomCode == 0 &&
          ((Game?.createdBy._id == user && (
            <div className="room-code-first-user">
              {console.log("current user", user)}
              <div className="room-code-middle-heading-user1">
                Set Room Code
                <h6>लूडो किंग से रूम कोड अपलोड करें</h6>
              </div>

              <input
                placeholder="Enter Room Code"
                type="number"
                value={roomcode}
                onChange={(e) => setRoomcode(e.target.value)}
              />
              <button
                type="button"
                onClick={() => {
                  if (/^0\d{7}$/.test(roomcode)) {
                    getPost();
                  } else {
                    ToastError(
                      "Please Enter A Valid 8-Digit Code That Starts With 0"
                    );
                  }
                }}
              >
                {loading ? "Loading..." : "SET ROOM CODE"}
              </button>
              <div className="room-code-marquee">
                <audio src={creatorAudio} autoPlay></audio>
                <marquee>
                  कृपया लूडो किंग से रुम कोड निकल कर SET ROOM CODE मे डाल दे
                </marquee>
              </div>
            </div>
          )) ||
            (Game?.acceptedBy._id == user && (
              <div className="room-code-second-user">
                <div className="room-code-middle-heading">
                  Waiting for Room Code
                  <h6>रूम कोड का इंतजार है।</h6>
                </div>

                <div>
                  <img
                    className="room-code-second-user-loading-img"
                    width="100px"
                    src={loading_color}
                  />
                </div>
                <div className="room-code-marquee">
                  <audio src={acceptorAudio} autoPlay></audio>
                  <marquee>
                    कृपया इन्तजार करें सामने वाला यूजर लूडो किंग से रूम कोड
                    निकाल कर दे रहा है
                  </marquee>
                </div>
              </div>
            ))))}
    </div>
  );
};

export default RoomCodeMiddle;
