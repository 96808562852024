import React, { useContext, useState } from "react";
import KycFormHeader from "../../kyc/kyc_form/kyc_form_header/KycFormHeader";
import TopButtons from "./withdraw_top_buttons/TopButtons";
import PaymentMode from "./payment_mode/PaymentMode";
import WithdrawForm from "./withdraw_form/WithdrawForm";
import DataContext from "../../../context/DataContext";
// import KycCard from "../../../components/kycCard";

import UnverifiedKyc from "./unverified_kyc/UnverifiedKyc";

const WithdrawData = () => {
  const { state, dispatch } = useContext(DataContext);
  const [withdrawOutput, setWithdrawOutput] = useState(
    state.web_settings.data.withdrawOutput
  );

  return (
    <>
      <TopButtons />
      {state.user_data.verified === "verified" ? (
        <>
          {withdrawOutput ? (
            <>
              <PaymentMode chipsAvilable={state.user_data.withdrawAmount} />
              <WithdrawForm userData={state.user_data} dispatch={dispatch} />
            </>
          ) : (
            <div style={{ color: "white" }}>
              Payment is not active yet, try after some time
            </div>
          )}
        </>
      ) : (
        <>
          <UnverifiedKyc />
        </>
      )}
      {/* {state.user_data.verified === "verified" && }
      {state.user_data.verified === "verified" && } */}
    </>
  );
};

export default WithdrawData;
