import React, { useContext, useEffect, useRef, useState } from "react";
import "./AddCashData.css";
import DataContext from "../../../context/DataContext";
import axios from "axios";
import { ToastError } from "../../../helper/tost_helper/ToastError";
import { useNavigate } from "react-router-dom";
import TopButton from "./top_button/TopButton";
import AddCashMessage from "./add_cash_message/AddCashMessage";
import ManualPaymentPage from "./manual_payment_page/ManualPaymentPage";
import AddCashFooter from "./add_cash_footer/AddCashFooter";
import AddCashPayment from "./add_cash_payment/AddCashPayment";

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}

const AddCashData = () => {
  const { state, dispatch } = useContext(DataContext);

  function walletUpdate() {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        // setUser(res.data);
        dispatch({ type: "USER_DATA", data: res.data });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          // history.pushState("/login")
        }
      });
  }

  // const history = useHistory();
  const navigate = useNavigate();
  let method = useRef();
  let checkInterval;
  const [userAllData, setUserAllData] = useState();

  // const [globalState, setGlobal] = useState();
  const [next, setNext] = useState(1);
  const [process, setProcess] = useState(false);
  const [isMobile, setMobile] = useState(false);

  const [isCashFreeActive, setCashFreeActive] = useState(false);
  const [isPhonePeActive, setPhonePeActive] = useState(false);
  const [isRazorPayActive, setRazorPayActive] = useState(false);
  const [isDecentroActive, setDecentroActive] = useState(false);
  const [isUpiGatewayActive, setUpiGatewayActive] = useState(false);

  const [isManualPaymentActive, setManualPaymentActive] = useState(false);
  const [isManualUPIQR, setManualUPIQR] = useState(false);

  const [RazorPayKey, setRazorPayKey] = useState(false);
  const [RazorPayAccountName, setAccountName] = useState("Skill Based Gaming");

  const [qrCode, setQrCode] = useState();
  const [walletOption, setWalletOption] = useState("airtel");
  const [bankCode, setBankCode] = useState(3003);

  const [account_mail_id, setAccount_mail_id] = useState();
  const [account_name, setAccount_name] = useState();
  const [accountPhone, setAccountPhone] = useState();

  const [scrnshot, setScrnshot] = useState(null);
  const [scrnshot1, setScrnshot1] = useState("");
  const [upiUtrNum, setupiUtrNum] = useState("");
  const [globalState, setGlobalState] = useState(0);

  const [isPaymentMethodChecked, setIsPaymentMethodChecked] = useState("");
  const [isQrCodeClicked, setIsQrCodeClicked] = useState(false);
  const [showManualPaymentPage, setManualPaymentPage] = useState(false);

  const handleChange = (e) => {
    setScrnshot1(URL.createObjectURL(e.target.files[0]));
    setScrnshot(e.target.files[0]);
  };

  const handleAmountChange = (e) => {
    console.log("Amount entered is :- ", e.target.value);
    let enteredValue = parseInt(e.target.value);
    setGlobalState(enteredValue);
  };

  const handlePayButtonClicks = () => {
    console.log("Handle payment is clicked......");
    console.log("global state is :- ", globalState);
    console.log("payment method is:-", isPaymentMethodChecked);

    if (globalState >= 20 && isPaymentMethodChecked === "online") {
      console.log("Inside the system to call phonePe system");
      method.current = "upipay"; // Assuming method is a ref
      phonePeDepositeUpi("link", "upi", "upipay", "Phonepe");
      setNext(2);
    } else {
      ToastError(
        "Please enter 20 or more then 20 add chip amount \n select payment mode"
      );
      return;
    }
  };

  const ManualPayment = async (e) => {
    e.preventDefault();
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    const formData = new FormData();

    formData.append("Transaction_Screenshot", scrnshot);
    formData.append("amount", globalState);
    formData.append("referenceId", upiUtrNum);

    const response = await axios.post(
      baseUrl + `manual/deposit/txn`,
      formData,
      { headers }
    );
    console.log(response.data);
    if (response.data.status === "Pending") {
      setTimeout(() => {
        axios
          .get(baseUrl + `manual/depositstatus/${response.data._id}`, {
            headers,
          })
          .then((res) => {
            //console.log(res);
            const icon = res.data.status === "PAID" ? "success" : "danger";
            var title = "";
            if (res.data && res.data.status === "PAID") {
              title = "Deposit submitted successfully";
            } else if (res.data && res.data.status === "Pending") {
              title = "Deposit Transaction in process.";
            } else if (!res.data.status) {
              title = "Deposit Transaction Rejected Due to invalid UTR Number.";
            }

            // history.push("/");
            navigate("/");
            setTimeout(() => {
              setProcess(false);
              ToastError(title);
            }, 1000);
          });
      }, 30000);
      setProcess(true);
    } else {
      setProcess(false);
      ToastError("Deposit Failed");
    }
  };

  function loadRazorpayScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpayPaymentSdk(
    channel,
    method,
    upiMethod,
    razorpay
  ) {
    const res = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. please check are you online?");
      return;
    }
    //setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    // creating a new order and sending order ID to backend
    const response = await axios.post(
      baseUrl + `user/razorpay_order`,
      {
        amount: globalState,
        channel: channel,
        payment_method: method,
        provider: walletOption,
        bankCode: bankCode,
        account_name: account_name,
        payment_gatway: razorpay,
      },
      { headers }
    );

    if (!response) {
      alert("Server error. please check are you online?");
      return;
    }

    //console.log(response.data.orderdata);
    // Getting the order details back
    let order_id = response.data.txnID;
    let order_token = response.data.orderdata.id;
    const data = response.data.orderdata;
    const options = {
      key: RazorPayKey,
      name: RazorPayAccountName,
      description: "Skill Based Game Tournament",
      order_id: data.id,
      prefill: {
        name: account_name,
        email: account_mail_id,
        contact: accountPhone,
      },
      handler: async (response) => {
        try {
          const paymentId = response.razorpay_payment_id;

          //const url = baseUrl+`rozarpay/capture/${paymentId}`;
          //const captureResponse = await axios.post(url, {},{headers})
          //console.log(captureResponse.data);
          checkrazorpaydeposit(order_id, order_token, "SUCCESS", paymentId);
        } catch (err) {
          checkrazorpaydeposit(order_id, order_token, "FAILED");
          console.log(err);
        }
      },
      theme: {
        color: "#686CFD",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const checkrazorpaydeposit = (
    order_id,
    order_token,
    order_status,
    paymentId
  ) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `razorpaydesposit/response`,
        { order_id, order_token, order_status, paymentId },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        // history.push("/landing");
        navigate("/");
        setTimeout(() => {
          ToastError(title);
        }, 1000);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  const decentroDepositeUpi = (channel, method, upiMethod, decentropay) => {
    //account_name
    //account_mail_id
    //alert(account_name);
    //if(account_name && account_mail_id){

    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get("https://morniinc.in/payment.php?amount=" + globalState, {
        accountID: "AID_2309051230571814",
        secret_key: "KEY_9e88747cf61aede3bffe95122ded7372",
        payment_id: "2345678uyf",
        payment_purpose: "game",
        buyer_name: "pradeep",
        buyer_phone: "9236547898",
        buyer_email: "p@gmail.com",
        pay_amount: globalState,
      })
      .then((res) => {
        console.log(res);
        if (res.data.response.status === "Success") {
          let order_id = "res.data.txnID";
          let order_token = "res.data.data.decentroTxnId";
          //setProcess(false);
          window.open(res.data.response.pay_link);
          setTimeout(() => {
            //checkdecentrodeposit(order_id, order_token)
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          ToastError(res.data.data.msg);
        }
      })
      .catch((e) => {
        alert(e);
      });
    // }else{
    //   Swal.fire({
    //     title: 'Account holder name or Mail id is required',
    //     icon: 'danger',
    //     confirmButtonText: "OK",
    // });
    // }
  };

  const phonePeDepositeUpi = (channel, method, phonepay) => {
    //account_name
    //account_mail_id
    //alert(account_name);
    //if(account_name && account_mail_id){

    if (globalState < 20) {
      ToastError("Please enter 20 or more then 20 add chip amount");
      return;
    }

    setProcess(true);
    const access_token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `phonepev1/accept?amount=${globalState}`,
        {
          amount: globalState,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
          account_name: account_name,
          payment_gatway: phonepay,
        },
        { headers: headers }
      )
      .then((res) => {
        console.log(res.data);
        // console.log(res.data.data.data.transactionId);
        // console.log(res.data.txnID);
        if (res.data.success === true) {
          let order_id = "res.data.data.txnID";
          let order_token = "res.data.data.data.txnID";
          //setProcess(false);
          window.location.assign(
            res.data.data.instrumentResponse.redirectInfo.url
          );
          //   var windowReference = window.open();

          //     myService.getUrl().then(function(url) {
          //          windowReference.location = url;
          //     });
          setTimeout(() => {
            console.log("phonepe deposit check order ID", order_id);
            checkphonepedeposit(order_id, order_token);
            //history.push('/');
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          ToastError(res.data.msg);
        }
      })
      .catch((e) => {
        alert(e);
      });
    // }else{
    //   Swal.fire({
    //     title: 'Account holder name or Mail id is required',
    //     icon: 'danger',
    //     confirmButtonText: "OK",
    // });
    // }
  };

  const checkphonepedeposit = (order_id, order_token) => {
    console.log(order_token);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `phonepestatus/response`,
        { order_id, order_token },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "Deposit submited successfully"
            : "Transaction in failed";

        if (res.data.status == "Pending") {
          setTimeout(() => {
            checkphonepedeposit(order_id, order_token);
            ToastError(title);
          }, 6000);
        } else {
          // history.push("/");
          navigate("/");
          setTimeout(() => {
            ToastError(title);
          }, 1000);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  const depositUpiGateway = (channel, method, upiMethod, upigateway) => {
    //account_name
    //account_mail_id
    //alert(account_name);
    //if(account_name && account_mail_id){

    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/depositeupi`,
        {
          amount: globalState,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
          account_name: account_name,
          account_mail_id: account_mail_id,
          payment_gatway: upigateway,
        },
        { headers }
      )

      .then((res) => {
        if (res.data.data.status) {
          let order_id = res.data.txnID;
          let order_token = res.data.data.data.order_id;

          window.location.assign(res.data.data.data.payment_url);

          setTimeout(() => {
            checkupideposit(order_id, order_token);
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          ToastError(res.data.data.msg);
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const checkupideposit = (order_id, order_token) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `depositupipay/response`,
        { order_id, order_token },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        // history.push("/landing");
        navigate("/");
        setTimeout(() => {
          ToastError(title);
        }, 1000);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          // localStorage.removeItem('token');
          // localStorage.removeItem('token');
          window.location.reload();
        }
      });
  };

  const deposit = (channel, method, upiMethod) => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/deposite`,
        {
          amount: globalState,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
        },
        { headers }
      )
      .then((res) => {
        if (res.data.data.payment_method === "app") {
          window.location.href = res.data.data.data.url;
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
        } else if (res.data.data.payment_method === "netbanking") {
          window.location.href = res.data.data.data.url;
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
        } else if (
          res.data.data.channel === "link" &&
          res.data.data.payment_method === "upi"
        ) {
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
          window.location.href = res.data.data.data.payload[upiMethod];
        } else if (
          res.data.data.channel === "qrcode" &&
          res.data.data.payment_method === "upi"
        ) {
          setQrCode(res.data.data.data.payload.qrcode);
          setProcess(false);
          setNext(3);
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const checkout = (paymentID) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `checkout/deposite/txn`,
        { txnID: paymentID },
        { headers }
      )
      .then((res) => {
        // alert(res.data.txnStatus)
        if (res.data.txnStatus === "PAID") {
          walletUpdate();
          clearInterval(checkInterval);
          ToastError(res.data.msg);
          setProcess(false);
          setNext(1);
        } else if (res.data.txnStatus === "FAILED") {
          walletUpdate();
          clearInterval(checkInterval);
          ToastError(res.data.msg);
          setProcess(false);
          setNext(1);
        }
        // else if(res.data.txnStatus!="PENDING")
        // {
        //     clearInterval(checkInterval);
        //     Swal.fire({
        //       title: res.data.msg,
        //       icon: 'info',
        //       confirmButtonText: "OK",
        //   });
        // }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
        setAccount_mail_id(res.data.Email);
        setAccount_name(res.data.holder_name);
        setAccountPhone(res.data.Phone);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          // history.push("/login");
          navigate("/login");
        }
      });

    axios
      .get(baseUrl + `gatewaysettings/data`)
      .then((res) => {
        //console.log(res);
        setCashFreeActive(res.data.isCashFreeActive);
        setRazorPayActive(res.data.isRazorPayActive);
        setDecentroActive(false);
        setPhonePeActive(res.data.Phonepestatus);
        setRazorPayKey(res.data.RazorPayKey);
        setAccountName(res.data.AccountName);
        setManualPaymentActive(res.data.isManualPaymentActive);
        setUpiGatewayActive(res.data.UpiGateway);
        setManualUPIQR(res.data.isManualUPIQR);
      })
      .catch((e) => {
        setCashFreeActive(false);
        setRazorPayActive(false);
        setDecentroActive(true);
        setPhonePeActive(false);
        setRazorPayKey(false);
        setManualPaymentActive(false);
        setManualUPIQR(false);
        setUpiGatewayActive(false);
        setAccountName("Skill Based Gaming");
      });

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setMobile(true);
    }
  }, []);

  axios
    .get(baseUrl + "settings/data", {})
    .then((res) => {
      setPaymentMethod(res.data.isPaymentMethod);
      setManualUPI(res.data.manualUpi);
    })
    .catch((e) => {
      setPaymentMethod("");
    });

  const handleQrCodeClick = () => {
    setIsQrCodeClicked(true);
  };

  const handlePayButtonClick = () => {
    method.current = "upipay";
    displayRazorpayPaymentSdk("link", "upi", "upipay", "razorpay");
  };

  const [manualUpi, setManualUPI] = useState("");
  const [manualAmount, setManualAmount] = useState("");
  const [manualUTR, setManualUTR] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmitdata = async (e) => {
    e.preventDefault();

    if (manualUTR.length < 12 || manualUTR.length > 12) {
      ToastError("UTR number must be 12 digit");
      setLoading(false);
      return;
    }

    setLoading(true);

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    const formData = new FormData();

    formData.append("amount", manualAmount);
    formData.append("utrNumber", manualUTR);

    const response = await axios.post(baseUrl + `temp/deposite`, formData, {
      headers,
    });
    console.log(response.data);
    if (response.data.status === "pending") {
      setProcess(false);
      ToastError("Deposit Successfully submitted");
      setLoading(false);
      // history.push("/");
      navigate("/");
    } else {
      setProcess(false);
      ToastError("Deposit Failed! " + response.data.message);
      setLoading(false);
    }
  };

  function handelManualPaymentpage() {
    setManualAmount(globalState);
    setManualPaymentPage(true);
  }

  const [postsecond, setPostsecond] = useState([]);

  useEffect(() => {
    axios.get(baseUrl + "getmessageManualPayment").then((response) => {
      setPostsecond(response.data);
    });
  }, []);

  return (
    <>
      <div className="add-case-data-container">
        <TopButton />
        <AddCashMessage />
        <AddCashPayment
          handleAmountChange={handleAmountChange}
          setIsPaymentMethodChecked={setIsPaymentMethodChecked}
          handlePayButtonClicks={handlePayButtonClicks}
          isPaymentMethodChecked={isPaymentMethodChecked}
          handelManualPaymentpage={handelManualPaymentpage}
        />

        {/* {!showManualPaymentPage && (
          <ManualPaymentPage
            manualUpi={manualUpi}
            manualAmount={manualAmount}
            setManualAmount={setManualAmount}
            manualUTR={manualUTR}
            setManualUTR={setManualUTR}
            loading={loading}
            handleSubmitdata={handleSubmitdata}
          />
        )} */}
        <AddCashFooter />
      </div>
    </>
  );
};

export default AddCashData;
