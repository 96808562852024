import React from "react";
import "./ShowReferralCard.css";

const ShowReferralCard = ({
  title,
  userName,
  roomcode,
  date,
  balance,
  amount,
}) => {
  let date_data = new Date(date);
  // console.log(date_data);
  let date_day = date_data.getDate();
  let date_month = date_data
    .toLocaleString("default", { month: "long" })
    .substring(0, 3);
  let time = date_data.toLocaleTimeString();
  return (
    <>
      <div className="transaction-game-card-container">
        <div className="game-date-container">
          {/* <img width="22px" src={wallet_icon} alt="Wallet Icon" /> */}
          {/* <AccountBalanceWalletIcon sx={{ color: "green" }} /> */}
          <div className="game-date">
            {date_day}-{date_month}
          </div>
          <div className="game-time">{time}</div>
        </div>
        <div className="game-detail-container">
          <div className="game-detail-title">
            Referral From:<span> {userName}</span>
          </div>
          {/* <div className="game-detail-status">room code:- {roomcode}</div> */}
        </div>
        <div className="game-amount-container">
          <div className="game-amount"> (+){amount}</div>
          <div className="game-closing-balance">Closing balance: {balance}</div>
        </div>
      </div>
    </>
  );
};

export default ShowReferralCard;
