import React, { memo, useContext, useState } from "react";
import "./BatAmountBar.css";
import { useLocation } from "react-router-dom";
import { ToastError } from "../../../../helper/tost_helper/ToastError";
import AxiosPost from "../../../../api's/AxiosPost";
import axios from "axios";
import axiosHelper from "../../../../api's/axiosHelper";
import DataContext from "../../../../context/DataContext";

const BatAmountBar = ({
  isMode,
  socket,
  // Allgames,
  // gameAmount,
  // setGame_Ammount,
  // loading,
  // createOwnBat,
}) => {
  const { state } = useContext(DataContext);
  console.log(state.user_data._id);
  const [game_type, setGame_type] = useState(
    useLocation().pathname.split("/")[2]
  );
  const [gameAmount, setGame_Ammount] = useState("");
  const [loading, setLoading] = useState(false);
  // console.log("Enterd bet amount is:- ", gameAmount);

  const createOwnBat = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    if (!access_token) {
      ToastError("Login Again");
      window.location.href = "/";
      return;
    }

    if (!gameAmount || gameAmount < 50) {
      ToastError("Enter amount above 50", 2000);
      return;
    } else if (isMode === "liteMode" && gameAmount > 25000) {
      ToastError("Please enter game amount 50 to 25000", 2000);
      return;
    }

    setLoading(true);
    console.log("Chalangeeeeeeeeeeee created Apiiiiiiiii calleddddddddd");
    console.log(`[${new Date().toISOString()}]`, "Api called");

    // axiosHelper.post(
    //   `challange/create`,
    //   {
    //     gameAmount,
    //     gameType: game_type,
    //   },
    //   { headers }
    // );

    // socket.emit("gameCreated");
    // setTimeout(() => {
    //   socket.emit("gameCreated");
    // }, 400);

    // setInterval(() => {
    //   console.log("intervalllllllllllllllllllllllllllllllllllll");
    //   socket.emit("gameCreated");
    // }, 100);

    let ws_data = {
      id: state.user_data._id,
      gameAmount: gameAmount,
      gameType: game_type,
    };

    socket.emit("gameCreated", ws_data);

    // await axiosHelper
    //   .post(
    //     `challange/create`,
    //     {
    //       gameAmount,
    //       gameType: game_type,
    //     },
    //     { headers }
    //   )
    //   .then((res) => {
    //     console.log("response of post is :- ", res);
    //     setLoading(false);
    //     if (res.data.msg) {
    //       // console.log("api response msg------------");
    //       // console.log("Response received ----inside if ");
    //       console.log(res.data.msg);
    //       ToastError(res.data.msg, 2000);
    //     } else {
    //       console.log("Response received ----inside else ");
    //       console.log(
    //         "Chalangeeeeeeeeeeee created gameCreated socket callleeedddd..."
    //       );
    //       //pass the socket to this component using probs from gameData
    //       console.log("Socketttttttt..........triggered......");
    //       console.log(`[${new Date().toISOString()}]`, "Your message here");
    //       socket.emit("gameCreated");
    //       // Allgames();
    //     }
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     if (err.response.status === 401) {
    //       localStorage.removeItem("token");
    //       localStorage.removeItem("token");
    //       window.location.reload();
    //     }
    //     if (err.response.status === 400 || err.response.status === 429) {
    //       ToastError("Please refresh!");
    //     }
    //     console.log(err);
    //   });
    setGame_Ammount("");
    setTimeout(() => {
      setLoading(false);
    }, 1500);
    // setLoading(false);
  };

  return (
    <>
      <div className="bat-amount-bar-container">
        <input
          type="text"
          placeholder="Enter Amount"
          value={gameAmount}
          onChange={(e) => setGame_Ammount(e.target.value)}
        />
        {!loading ? (
          <button onClick={createOwnBat}>Click</button>
        ) : (
          <button className="bat-amount-button-loading">Wait...</button>
        )}
      </div>
    </>
  );
};

export default memo(BatAmountBar);
