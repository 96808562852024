import React from 'react'
import Rightside from '../../components/rightside/Rightside'
import LeftSideCard from '../../components/card/LeftSideCard'
// import HomeData from '../home/HomeData'
import LandingPageData from './LandingPageData'
import SupportIcon from '../../components/support_icon/SupportIcon'
import Navbar from '../../components/navbar/Navbar'
import Leftside from '../../components/leftside/Leftside'


const Landing = () => {
  return (
    <>
    <Navbar/>
    <Leftside>
      <LandingPageData/>
      </Leftside>
      
    {/* <Navbar/>
      <LeftSideCard>
        <LandingPageData/>
      </LeftSideCard>
      <Rightside/>
      <SupportIcon/> */}

      
    </>
  )
}

export default Landing