import React, { useContext } from "react";
// import "./Profile/.css"
import Leftside from "../../components/leftside/Leftside";
import ProfileCard from "./ProfileCard";
import ProfileTop from "./ProfileTop";
import ProfileBottom from "./ProfileBottom";
import ProfileLogout from "./ProfileLogout";
import Navbar from "../../components/navbar/Navbar";
import DataContext from "../../context/DataContext";

const Profile = () => {
  const { state, dispatch } = useContext(DataContext);
  return (
    <>
      <Navbar />
      <Leftside>
        <ProfileTop state={state} dispatch={dispatch} />
        <ProfileBottom state={state} dispatch={dispatch} />
        <ProfileLogout />
      </Leftside>
    </>
  );
};

export default Profile;
