import React from "react";
import "./LudoKingDownload.css";
import androidDownload from "../../../../../assets/images/kdandplay.jpeg";
import iosDownload from "../../../../../assets/images/kdiosplay.jpeg";

const LudoKingDownload = () => {
  return (
    <div className="ludo-king-download-container">
      <div className="ludo-king-download-text">
        Play Ludo Game On <span>LUDO KING</span> App
      </div>
      <div className="ludo-king-download-buttons">
        <a href="#">
          <button>
            <img width="170px" src={androidDownload} alt="" />
          </button>
        </a>

        <a href="#">
          <button>
            <img width="170px" height="50px" src={iosDownload} alt="" />
          </button>
        </a>
      </div>
    </div>
  );
};

export default LudoKingDownload;
