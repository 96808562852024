import React from 'react'
import "./ProfileCard.css"

const ProfileCard = ({children}) => {
  return (
    <div className='profile-container'>
        <div className='profile-data-container'>
            {children}
        </div>
    </div>
  )
}

export default ProfileCard