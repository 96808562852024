import React from "react";
import "../running_battle_card/RunningBattleCard.css";
import moneyimg from "../../../../../assets/images/money.png";

const RunningBattleStaticCard = ({ data, generateRandomString }) => {
  return (
    <>
      <div className="running-battle-card-container">
        <div className="running-battle-card-heading">
          <div className="running-battle-amount">
            Playing For
            <img width="22px" src={moneyimg} alt="money image" />
            <span>{data.entryFee}</span>
          </div>
          <div className="running-battle-prize">
            Prize
            <img width="22px" src={moneyimg} alt="money image" />
            <span>{data.prize}</span>
          </div>
        </div>
        <div className="running-battle-card-line"></div>
        <div className="running-battle-users">
          <div>{data.userName1}</div>
          <div style={{ color: "#dc3546", fontSize: "18px" }}>v/s</div>
          <div>{generateRandomString(6)}</div>
        </div>
      </div>
    </>
  );
};

export default RunningBattleStaticCard;
