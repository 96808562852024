import React, { useContext, useState } from "react";
import "./ProfileTop.css";
import ProfileCard from "./ProfileCard";
import avtar from "../../assets/images/avtar/user.png";
import EditIcon from "@mui/icons-material/Edit";
import DataContext from "../../context/DataContext";
import Kyc from "../../components/kycCard/KycCard";
import axiosHelper from "../../api's/axiosHelper";
import { ToastError } from "../../helper/tost_helper/ToastError";

import axios from "axios";
const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const websocketURL = process.env.REACT_APP_SOCKET_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}


const ProfileTop = ({ state, dispatch }) => {
  // let user_name = state.user_data.name;
  let user_phone = state.user_data.phone;

  const [user_name, setUser_name] = useState(state.user_data.name)

  const [isEdit, setIsEdit] = useState(false);

  const userNameEditHandler = async () => {

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    if (user_name?.length > 20) {
      ToastError("Name should not be longer than 20 characters");
      return;
    }
    else if (user_name?.length < 1) {
      ToastError("Name should't be less than 1 characters");
      return;
    }

    await axiosHelper.patch(`user/edit`,
      {
        name: user_name
      },
      { headers }
    ).then((res) => {
      console.log("RESPONSE AT PROFILE:--", res.data);

      if (res.data == "User name already exist!") {
        ToastError(res.data);
      }
      else {
        setUser_name(res.data.name);
        axiosHelper.get(`me`, { headers }).then((res) => {
          dispatch({ type: "USER_DATA", data: res.data });
        })

      }
      setIsEdit(false);
      // window.location.reload();
    }).catch((e) => {
      console.log(e);
    })
  }

  // const UpdateProfile = async () => {
  //   const access_token = localStorage.getItem("token");
  //   const headers = {
  //     Authorization: `Bearer ${access_token}`,
  //   };
  //   const data = await axios
  //     .patch(
  //       baseUrl + `user/edit`,
  //       {
  //         name:user_name,
  //       },
  //       { headers }
  //     )
  //     .then((res) => {
  //       console.log("name edit response;-", res);
  //       console.log(res.data);
  //       if (res.data == "User name already exist!") {
  //         // Swal.fire({
  //         //   title: res.data,
  //         //   icon: "error",
  //         //   confirmButtonText: "OK",
  //         // });
  //       } else {
  //         // setName(res.data);
  //         // UserALL();
  //       }
  //     });
  // };



  return (
    <ProfileCard>
      <div className="profile-top-heading">Profile</div>
      <div className="profile-top-details">
        <img
          className="profile-top-avtar"
          width="80px"
          height="80px"
          src={avtar}
        />
        <div>
          <div className="profile-top-username">
            {!isEdit ? <> <input type="text" value={user_name} disabled />
              <EditIcon sx={{ fontSize: 18, color: "#219ebc" }} onClick={() => { setIsEdit(true) }} />
            </> :
              <> <input className="edit-username-input" type="text" value={user_name} onChange={(e) => {
                const inputValue = e.target.value;
                const nameWithoutSpaces = inputValue.replace(/\s/g, '');
                setUser_name(nameWithoutSpaces)
              }}
                title="Name should not be longer than 20 characters"
                // maxlength="20"
                minlength="5" required />
                <button className="username-edit-button" onClick={userNameEditHandler}>Submit</button>
              </>}

            {" "}
          </div>
          <div className="profile-top-mobile"> {user_phone}</div>
        </div>
      </div>
      <Kyc />
    </ProfileCard>
  );
};

export default ProfileTop;
