import React from "react";
import "./TransactionHistoryCard.css";
import wallet_icon from "../../../../assets/images/icons/wallet1.svg";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

const TransactionHistoryCard = ({ title, status, date, balance, amount }) => {
  let date_data = new Date(date);
  // console.log(date_data);
  let date_day = date_data.getDate();
  let date_month = date_data
    .toLocaleString("default", { month: "long" })
    .substring(0, 3);
  console.log("date of month", date_month.substring(0, 3));
  console.log(typeof date_month);
  let time = date_data.toLocaleTimeString();
  // console.log("time is:-", time);
  // console.log("Title is :- ", title);

  return (
    <>
      <div className="transaction-history-card-container">
        <div className="history-date-container">
          {/* <img width="22px" src={wallet_icon} alt="Wallet Icon" /> */}
          {/* <AccountBalanceWalletIcon sx={{ color: "green" }} /> */}
          <div className="history-date">
            {date_day}-{date_month}
          </div>
          <div className="history-time">{time}</div>
        </div>
        <div className="history-detail-container">
          <div className="history-detail-title">
            {title?.toString().toUpperCase()}
          </div>
          <div className="history-detail-status">Status:- {status}</div>
        </div>
        <div className="history-amount-container">
          {(title === "deposit" && status !== "Pending") ||
          title === "bonus" ||
          title === "YOU WON IT" ? (
            <div className="history-amount">(+){amount}</div>
          ) : status === "Pending" ? (
            <div className="history-amount-pending"> {amount}</div>
          ) : (
            <div className="history-amount-withdraw">(-){amount}</div>
          )}

          {/* <div className="history-closing-balance">
            Closing balance: {balance}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TransactionHistoryCard;
