import React from "react";
import "./RoomCode.css";
import Navbar from "../../components/navbar/Navbar";
import Leftside from "../../components/leftside/Leftside";
import RoomCodeData from "./room_code_data/RoomCodeData";

const RoomCode = () => {
  return (
    <>
      <Navbar />
      <Leftside>
        <RoomCodeData />
      </Leftside>
    </>
  );
};

export default RoomCode;
