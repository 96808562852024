import React from 'react'
import logo from "../../assets/images/logo.jpg";
import SecurityIcon from '@mui/icons-material/Security';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Link } from 'react-router-dom';
import "./LandingPageData.css"

const LandingPageData = () => {
  return (
    <>
    <div className='top-part'>
  <h1>Welcome to KD124</h1>
  <img width="150px" src={logo}  alt="kd124 logo"/>
  </div>

  <div className='middle-part'>
    <div>PLAY LUDO</div> 
    & 
    <div>Win Real Cash!</div>
  </div>
  
  <div className='bottom-part'>
    <Link to="/login">
    <button className='bottom-part-btn'>Play</button>
    </Link>
    <div className='bottom-icons-container'>

  {/* <div className='bottom-icon'>
  <SecurityIcon  sx={{ fontSize: 32, color:"white" }}/>
      <div><div>Safe&</div>
      <div>Secure</div>
      </div>
      </div> */}

      <div className='bottom-icon-container'>
      <CurrencyRupeeIcon  sx={{ fontSize: 32, color:"rgba(255,255,255,0.7)" }}/>
      <div>
      <div>Instant</div>
      <div>Withdraw</div>
      </div>
      </div>
      
      <div className='bottom-icon-container'> 
        <AccountBalanceIcon  sx={{ fontSize: 32, color:"rgba(255,255,255,0.7)" }}/>
      <div>
      <div>5%</div>
      <div>commission</div>
      </div>
      </div>

      <div className='bottom-icon-container'>
        <SupportAgentIcon  sx={{ fontSize: 32, color:"rgba(255,255,255,0.7)" }}/>
      <div>
      <div>24x7</div>
      <div>Support</div>
      </div>
    </div>
    </div>
    <div className='game-warning'>This Game involves an element of financial risk and may be addictive please play responsibly and at your own risk</div>
  </div>
</>
  )
}

export default LandingPageData