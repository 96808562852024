import React, { useState } from "react";
import "./LostCard.css";
import Leftside from "../../../../components/leftside/Leftside";

const LostCard = ({ setState, Result }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      {/* <Leftside> */}
      <div className="lost-card-container">
        <button
          className="lost-card-close-button"
          onClick={() => setState(null)}
        >
          X
        </button>
        <div className="lost-card-text-div">
          <span>Are You Sure, You Lost The Game</span>
          <span>क्या आप निश्चित हैं कि आप यह गेम हार गए हैं?</span>
        </div>
        <div className="lost-card-input-div">
          <input
            type="checkbox"
            onChange={() => setIsActive((prev) => !prev)}
          />
          <span>Yes, I Confirm</span>
        </div>
        <div className="lost-card-buttons-div">
          {!isActive ? (
            <button className="yes-i-lost-deactive" disabled onClick={Result}>
              YES I LOST
            </button>
          ) : (
            <button className="yes-i-lost-active" onClick={Result}>
              YES I LOST
            </button>
          )}
          <button onClick={() => setState(null)}>NO</button>
        </div>
      </div>
      {/* </Leftside> */}
    </>
  );
};

export default LostCard;
