//

import axios from "axios";
import { ToastError } from "../helper/tost_helper/ToastError";

export default async function AxiosPost(url_end, data) {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  console.log(data);

  try {
    let response = await axios.post(`${baseUrl}${url_end}`, data);
    console.log(response);

    if (response.data.status === 101) {
      ToastError(response.data.msg, 2000);
    } else if (response.data.status === 200) {
      return response;
    }
  } catch (error) {
    console.log("Error:- 404 found", error.message);
    ToastError(error.code, 2000);
  }
}
