import React, { useContext } from "react";
// import './Home.css'
import HomeScreenCard from "../../components/card/HomeScreenCard";
// import HomeData from './HomeData'
import SupportIcon from "../../components/support_icon/SupportIcon";
import Leftside from "../../components/leftside/Leftside";
import HomeData from "./HomeData";
import Navbar from "../../components/navbar/Navbar";
import MessageCard from "../../components/card/MessageCard";
import DataContext from "../../context/DataContext";
import Kyc from "../../components/kycCard/KycCard";

const Home = () => {
  const { state } = useContext(DataContext);
  // state.user_data.verified
  return (
    <>
      <Navbar />

      <Leftside>
        {state.user_data.verified !== "verified" && <Kyc />}
        <HomeData />
      </Leftside>
    </>
  );
};

export default Home;
