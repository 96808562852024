import React from "react";
import "./AddCashFooter.css";
import gpayImg from "../../../../assets/images/icons/payment/gpay.svg";
import phonepeImg from "../../../../assets/images/icons/payment/phonepe.svg";
import paytmImg from "../../../../assets/images/icons/payment/paytm.svg";
import bhimPeImg from "../../../../assets/images/icons/payment/upi.svg";

const AddCashFooter = () => {
  return (
    <div className="add-cash-footer-container">
      <div className="add-cash-footer-heading">Payments Secured By</div>
      <div className="add-cash-footer-icons">
        <img height="28px" src={gpayImg} alt="" />
        <img height="28px" src={paytmImg} alt="" />
        <img height="28px" src={phonepeImg} alt="" />
        <img height="28px" src={bhimPeImg} alt="" />
      </div>
    </div>
  );
};

export default AddCashFooter;
