import React from 'react'
import "./Rules.css"
import Navbar from '../../components/navbar/Navbar'
import Leftside from '../../components/leftside/Leftside'

const Rules = () => {
  return (
    <>
    <Navbar/>
    <Leftside>
        <div className='rules-container'>
        
            <h4><strong style={{fontFamily:"Poppins"}}>Ludo Rules</strong></h4>
            <ol className="rules-list">
              <li style={{fontFamily:"Poppins"}}>
              यदि आप जान भुजकर Autoexit करते है तो भी आपको 100% Loss कर दिया जायेगा ! यदि दोनों प्लेयर में किसी की काटी खुली नहीं तो उसे हम कैंसिल कर सकते है !
              </li>
              <li style={{fontFamily:"Poppins"}}>
              यदि एक टोकन बाहर है और घर के पास है तो 30% Loss दिया जायेगा लेकिन यदि गेम खेला गया है और 2 काटी बहार आयी हो तो गेम को लेफ्ट करने वाले को 100% Loss कर दिया जायेगा !
              </li>
              <li style={{fontFamily:"Poppins"}}>
              Autoexit में यदि 1 टोकन बाहर है तो गेम कैंसिल किया जा सकता है लेकिन यदि आपने गेम जान भुजकर में छोड़ा होगा तो आपको Loss ही दिया जायेगा इसमें अंतिम निर्णय Admin का होगा !
              </li>
              <li style={{fontFamily:"Poppins"}}>
              यदि आपको लगता है की Opponent ने जानभूझकर गेम को Autoexit में छोड़ा है लेकिन Admin ने कैंसिल कर दिया है तो आपसे वीडियो प्रूफ माँगा जायेगा इसलिए हर गेम को रिकॉर्ड करना जरुरी है ! यदि आप वीडियो प्रूफ नहीं देते है तो गेम रिजल्ट एडमिन के अनुसार ही अपडेट किया जायेगा चाहे आप विन हो या गेम कैंसिल हो !
              </li>
              <li style={{fontFamily:"Poppins"}}>
              Game समाप्त होने के 15 मिनट के अंदर रिजल्ट डालना आवश्यक है अन्यथा Opponent के रिजल्ट के आधार पर गेम अपडेट कर दिया जायेगा चाहे आप जीते या हारे और इसमें पूरी ज़िम्मेदारी आपकी होगी इसमें बाद में कोई बदलाव नहीं किया जा सकता है !
              </li>
              <li style={{fontFamily:"Poppins"}}>
              Win होने के बाद आप गलत स्क्रीनशॉट डालते है तो गेम को सीधा Cancel कर दिया जायेगा इसलिए यदि आप स्क्रीनशॉट लेना भूल गए है तो पहले Live Chat में एडमिन को संपर्क करे उसके बाद ही उनके बताये अनुसार रिजल्ट पोस्ट करे !
              </li>
              <li style={{fontFamily:"Poppins"}}>
              दोनों प्लेयर की टोकन (काटी) घर से बाहर न आयी हो तो लेफ्ट होकर गेम कैंसिल किया जा सकता है ! [कैंसिल प्रूफ करने के लिए वीडियो आवश्यक होगा]
              </li>
              <li style={{fontFamily:"Poppins"}}>
              'कैंसिल' रिजल्ट डालने के बाद गेम प्ले करके जीत जाते है तो उसमे हमारी कोई ज़िम्मेदारी नहीं होगी अतः गेम कैंसिल करने के बाद स्टार्ट न करे अन्यथा वो कैंसिल ही माना जायेगा
              </li>
              <li style={{fontFamily:"Poppins"}}>
              एक बार रिजल्ट डालने के बाद बदला नहीं जा सकता है इसलिए सोच समझकर रिजल्ट पोस्ट करे गलत रिजल्ट डालने पर पेनल्टी भी लगायी जाएगी चाहे आपने वो गलती से डाला हो या जान भुजकर
              </li>
              <li style={{fontFamily:"Poppins"}}>
              Fresh आईडी गेम के मामले में केवल पासा के उपयोग के प्रमाण पर रद्द कर दिया जाएगा गोटी के खुले होने के बावजूद नहीं ? गेम का रिकॉड होना जरूरी है अगर कोई अपनी आईडी का नाम नो फ्रेश कर रका तो नो फ्रेश आईडी ही टेक करे जिनका इंटरेस्ट हो वही टेक करे टेबल को ( इसका अंतिम रिजल्ट एडमिन देगा )
              </li>
            </ol>
            <h4><strong style={{fontFamily:"Poppins"}}>Commission Rates:</strong></h4>
            <ol className="rules-list">
              <li style={{fontFamily:"Poppins"}}>
              Battle below <span style={{fontFamily:"Poppins"}} className="fw-bold"> 50₹ To 25000₹, 0%</span> commission will be charged on battle amount.
              </li>
            </ol>
        </div>
    </Leftside>
    </>
  )
}

export default Rules