import React, { useContext } from "react";
import "./OpenBattle.css";
import OpenBattleCard from "./open_battle_card/OpenBattleCard";
import battleIcon from "../../../../assets/images/battleIcon.png";
// import PendingIcon from "@mui/icons-material/Pending";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import { Link } from "react-router-dom";
import OpenBattleStaticCard from "./open_battle_static_card/OpenBattleStaticCard";
import DataContext from "../../../../context/DataContext";

const OpenBattle = ({
  data,
  created,
  allgame,
  user,
  game_type,
  deleteChallenge,
  getPost,
  RejectGame,
  winamountFun,
  AcceptChallang,
  updateChallenge,
  staticClick,
}) => {
  const { state, dispatch } = useContext(DataContext);
  console.log("Open battle card is triggered:------");
  console.log("create by user games :- ", created);
  // console.log("static data available at openBattle :-", data);
  // console.log("static change handler:- ", staticClick);
  console.log("All game data is:- ", allgame);
  // console.log("openbattle main card is trig...");
  return (
    <>
      <div className="open-battle-heading">
        <div className="open-battle-heading-text">
          {" "}
          <img width="22px" height="22px" src={battleIcon} />
          Open Battles
        </div>
        {/* <Link to="/rules"> */}
        <div
          className="battle-rules"
          onClick={() => {
            dispatch({ type: "CLICKED_FOR_RULES" });
          }}
        >
          <OfflineBoltIcon sx={{ fontSize: 20, color: "rgb(209, 146, 123)" }} />
          Rules
        </div>
        {/* </Link> */}
      </div>
      {/* <OpenBattleCard data={data1} /> */}

      {/* own created bets:-  */}

      {created &&
        created?.map(
          (allgame) =>
            allgame.gameType == game_type && (
              <OpenBattleCard
                key={allgame?._id}
                allgame={allgame}
                user={user}
                deleteChallenge={deleteChallenge}
                getPost={getPost}
                RejectGame={RejectGame}
                winamountFun={winamountFun}
                AcceptChallang={AcceptChallang}
                updateChallenge={updateChallenge}
              />
            )
        )}

      {/* all game bets */}
      {allgame &&
        allgame?.map(
          (allgame) =>
            (allgame.status == "new" ||
              (allgame.status == "requested" &&
                (user == allgame.createdBy._id ||
                  user == allgame.acceptedBy._id)) ||
              (allgame.status == "running" &&
                user == allgame.acceptedBy._id &&
                allgame.acceptorSeen == false)) &&
            allgame.gameType == game_type && (
              <OpenBattleCard
                key={allgame._id}
                allgame={allgame}
                user={user}
                deleteChallenge={deleteChallenge}
                getPost={getPost}
                RejectGame={RejectGame}
                winamountFun={winamountFun}
                AcceptChallang={AcceptChallang}
                updateChallenge={updateChallenge}
              />
            )
        )}

      {/* static bets */}
      {data.map((val, index) => {
        let data1 = {
          key: index,
          userName: val.playerName,
          entryFee: val.amount,
          prize: val.winAmount,
        };
        return <OpenBattleStaticCard data={data1} staticClick={staticClick} />;
      })}
    </>
  );
};

export default OpenBattle;
