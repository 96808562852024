import React, { useContext } from "react";
import "./RoomCodeTopButton.css";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import { Link } from "react-router-dom";
import DataContext from "../../../../context/DataContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const RoomCodeTopButton = () => {
  const { state, dispatch } = useContext(DataContext);
  return (
    <div className="room-code-button-container">
      <Link to="/home/Ludo Classics">
        <button className="room-code-back-button">
          <ArrowBackIcon sx={{ fontSize: 24 }} />
          <span>BACK</span>
        </button>
      </Link>

      <Link to="/rules">
        <div className="battle-rules">
          <OfflineBoltIcon sx={{ fontSize: 20, color: "rgb(209, 146, 123)" }} />
          Rules
        </div>
      </Link>
    </div>
  );
};

export default RoomCodeTopButton;
