import axios from "axios";
import React, { useEffect, useState } from "react";
import axiosHelper from "../../../../api's/axiosHelper";
import ShowGameCard from "./show_game_card/ShowGameCard";
import HistoryPaginate from "../history_paginate/HistoryPaginate";

const ShowGame = ({ user_id, searchRoomCode }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [game, setGame] = useState([]);
  const limit = 100;

  const Allgames = async (user_id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      console.log("Search Term:", searchRoomCode);

      const response = await axiosHelper.get(
        `game/history/user/${user_id}?page=${pageNumber}&_limit=${limit}`,
        { headers }
      );

      console.log("API Response:", response?.data);

      let gameHis = [];
      response?.data?.data?.forEach((ele) => {
        if (
          ele?.status === "completed" ||
          ele?.status === "cancelled" ||
          ele?.status === "conflict"
        ) {
          gameHis.push(ele);
        }
      });

      setGame(gameHis);
      setNumberOfPages(response.data.totalPages);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  useEffect(() => {
    Allgames(user_id);
  }, [pageNumber]);

  return (
    <>
      {game &&
        game
          ?.filter((item) => {
            const normalizedSearchTerm = searchRoomCode?.toLowerCase();
            const currentUserID = user_id;
            return (
              ((item?.acceptedBy && item?.acceptedBy._id === currentUserID) ||
                (item?.createdBy && item?.createdBy._id === currentUserID)) &&
              item?.roomCode?.toLowerCase().includes(normalizedSearchTerm)
            );
          })
          .map((val, index) => {
            let title = "None";
            if (val.status === "completed") {
              if (val.winner && val.winner._id === user_id) {
                title = "Won Against";
              } else {
                title = "Lost Against";
              }
            } else {
              title = val.status;
            }

            let userName = "test user";

            if (
              (val.acceptedBy && val.acceptedBy._id === user_id) ||
              (val.createdBy && val.createdBy._id === user_id)
            ) {
              if (val.acceptedBy) {
                userName = val.acceptedBy.name;
              } else {
                userName = val.createdBy.name;
              }
            }

            let balance = 0;

            if (val.createdBy && val.createdBy._id === user_id) {
              balance = val.Creator_closingbalance;
            } else if (val.acceptedBy && val.acceptedBy._id === user_id) {
              balance = val.Acceptor_closingbalance;
            }

            return (
              <ShowGameCard
                key={index}
                title={title}
                userName={userName}
                roomcode={val.roomCode}
                date={val.createdAt}
                amount={val.winAmount}
                balance={balance}
              />
            );
          })}

      {numberOfPages > 1 && (
        <HistoryPaginate
          currentPage={pageNumber}
          setPageNo={setPageNumber}
          totalPageNo={numberOfPages}
        />
      )}
    </>
  );
};

export default ShowGame;
