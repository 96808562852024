import React from 'react'

const AboutUs = () => {
  return (
    <div className='terms-conditions-content'>
      
                  <span style={{ fontFamily: "Poppins", fontWeight: "900" }}>
                    {" "}
                    Kd 124
                  </span>{" "}
                  ("or We") is a real-money online gaming product owned and
                  operated by Allinone Hax.
                  <br /> <br /> We are an HTML5 game-publishing company and our
                  mission is to make accessing games fast and easy by removing
                  the friction of app-installs.
                  <br />
                  <br />
                  Kd 124 is a skill-based real-money gaming platform accessible
                  only for our users in India. It is accessible on
                  <span style={{ fontFamily: "Poppins", color: "#DC3545" }}>
                    {" "}
                    https://kd124.com
                  </span>
                  . On Kd 124, users can compete for real cash in Tournaments
                  and Battles. They can encash their winnings via popular
                  options such as Paytm Wallet, UPI or Phonepe. <br />
                  <br />
                  <span style={{ fontFamily: "Poppins", fontWeight: "900" }}>
                    {" "}
                    Our Games
                  </span>{" "}
                  <br />
                  <br />
                  We have a wide-variety of high-quality, premium HTML5 games,
                  online games. Our games are especially compressed and
                  optimised to work on low-end devices, uncommon browsers, and
                  patchy internet speeds.
                  <br />
                  <br /> We have games across several popular categories:
                  Arcade, Action, Adventure, Sports & Racing, Strategy, Puzzle &
                  Logic. We also have a strong portfolio of multiplayer games
                  such as Ludo, Chess, 8 Ball Pool, Carrom, Ludo Tournament Tic
                  Tac Toe, Archery, Quiz, Chinese Checkers and more! Some of our
                  popular titles are: Escape Run, Bubble Wipeout, Tower Twist,
                  Cricket Gunda, Ludo With Friends. If you have any suggestions
                  around new games that we should add or if you are a game
                  developer yourself and want to work with us, don't hesitate to
                  contact us on{" "}
                  <span style={{ fontFamily: "Poppins", color: "#DC3545" }}>
                    support@kd124.com
                  </span>
                
    </div>
  )
}

export default AboutUs