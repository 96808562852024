import React from "react";
import "./UnverifiedKyc.css";
import KycCard from "../../../../components/kycCard/KycCard";

const UnverifiedKyc = () => {
  return (
    <div className="withdraw-disabled-container">
      Complete KYC to take Withdrawals
      <KycCard />
    </div>
  );
};

export default UnverifiedKyc;
