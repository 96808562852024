import React from "react";
import "./MatchStatus.css";
// import WinCard from "./win_card/WinCard";

const MatchStatus = ({ setStatus, Game, fecthStatus }) => {
  return (
    <>
      <div className="match-status-container">
        <div className="match-status-heading">Match Status</div>

        {fecthStatus !== null && fecthStatus !== undefined && (
          <div className="match-status-text">
            You have already updated your battle result for{" "}
            <span>
              <b>{fecthStatus}</b>
            </span>
          </div>
        )}

        {fecthStatus === null && (
          <>
            <div className="match-status-text">
              After completion of your game, select the status of the game and
              post your screenshot below.
            </div>
            <div className="match-buttons-container">
              {Game?.roomCode != 0 && (
                <button
                  className="i-won-button"
                  onClick={() => setStatus("win")}
                >
                  I WON
                </button>
              )}
              {/* <WinCard status={status} /> */}
              {Game?.roomCode != 0 && (
                <button
                  className="i-lost-button"
                  onClick={() => setStatus("lose")}
                >
                  I LOST
                </button>
              )}

              <button onClick={() => setStatus("cancelled")}>CANCEL</button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MatchStatus;
