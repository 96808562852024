import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Leftside from "../../components/leftside/Leftside";
import GameData from "./game_data/GameData";

const Game = () => {
  return (
    <>
      <Navbar />
      <Leftside>
        <GameData />
      </Leftside>
    </>
  );
};

export default Game;
