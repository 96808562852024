import React from "react";
import "./AddCashPayment.css";

const AddCashPayment = ({
  handleAmountChange,
  isPaymentMethodChecked,
  setIsPaymentMethodChecked,
  handlePayButtonClicks,
  handelManualPaymentpage,
}) => {
  return (
    <div className="add-cash-payment-container">
      <div className="add-cash-payment-input-div">
        <label>Enter Amount</label>
        {/* <input
          type="number"
          placeholder="Amount"
          min={20}
          max={10000}
          defaultValue=""
          onChange={handleAmountChange}
        /> */}
        <input
          type="tel"
          id="amountInput"
          placeholder="Amount"
          min={20}
          max={10000}
          className="form-control add-cash-input"
          defaultValue=""
          onChange={handleAmountChange}
        />
      </div>

      <div className="add-cash-mode-selection">
        <div className="add-cash-mode-selection-online">
          {/* <input
            type="radio"
            onChange={() => setIsPaymentMethodChecked("online")}
          />{" "} */}
          <input
            type="radio"
            name="whichPG"
            id="phonepe"
            className="form-check-input"
            defaultValue="phonepe"
            defaultChecked=""
            onChange={() => setIsPaymentMethodChecked("online")}
          />
          Online
        </div>
      </div>

      <button
        className="add-cash-payment-button"
        onClick={handlePayButtonClicks}
        // onClick={
        //   isPaymentMethodChecked === "manual"
        //     ? () => handelManualPaymentpage(true)
        //     : handlePayButtonClicks
        // }
      >
        Pay
      </button>
    </div>
  );
};

export default AddCashPayment;
