import React from "react";
import "./Maintenance.css";
import maintenance_image from "../../assets/images/under_maintenance.png";
import kd_logo_maintenance from "../../assets/images/logo.jpg";

const Maintenance = () => {
  return (
    <div className="undermaintenance-container">
      <img width="140px" src={kd_logo_maintenance} alt="KD124 logo" />
      <img
        width="400px"
        src={maintenance_image}
        alt="undermaintenance images"
      />
    </div>
  );
};

export default Maintenance;
