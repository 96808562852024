import React, { useState } from "react";
import "./WithdrawForm.css";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Link, useNavigate } from "react-router-dom";
import { ToastError } from "../../../../helper/tost_helper/ToastError";
import axios from "axios";

const WithdrawForm = (userData, dispatch) => {
  // const history = useHistory();
  const navigate = useNavigate();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [user, setUser] = useState(userData.userData);
  const [holderName, setHolder_name] = useState();
  const [accountNumber, setAccount_number] = useState();
  const [ifscCode, setIfsc_code] = useState();
  const [amount, setAmount] = useState();

  const updateBankDetails = async (e) => {
    e.preventDefault();
    let confirm = false;
    let nameRegex = /^(?=.{3,120}$)[a-zA-Z0-9 ',-_\/(),.]+(?<![\W])$/;
    let ifscRegex = /^[a-zA-Z0-9]{11}$/;
    let accountNumberRegex = /^[a-zA-Z0-9]{5,35}$/;
    if (!holderName || !accountNumber || !ifscCode) {
      ToastError("Invalid bank details");
      confirm = false;
    } else if (!ifscRegex.test(ifscCode)) {
      ToastError("Invalid IFSC code");
      confirm = false;
    } else if (!accountNumberRegex.test(accountNumber)) {
      ToastError("Invalid account number");
      confirm = false;
    } else if (user.withdrawAmount < 200) {
      ToastError("Insufficient balance");
      confirm = false;
      // return;
    } else if (!amount || amount < 200) {
      ToastError("Amount should be minimum 200");
      confirm = false;
      // return;
    } else {
      confirm = true;
    }

    console.log("value of confirm is:-", confirm);
    if (confirm) {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      const data = await axios
        .patch(
          baseUrl + `user/edit`,
          {
            holderName,
            accountNumber,
            ifscCode,
            upiId: "",
            bankDetails: true,
          },
          { headers }
        )
        .then((res) => {
          // console.log("updata bank details", res);
          console.log("response is :- ", res);
          if (res.data.subCode === "200") {
            ToastError(res.data.msg);
            console.log(" user.wonAmount", user.wonAmount);
            console.log("user.loseAmount", user.loseAmount);
            console.log(" user.totalDeposit", user.totalDeposit);
            console.log("user.referralEarning", user.referralEarning);
            console.log("user.holdBalance", user.holdBalance);
            console.log("user.totalBonus", user.totalBonus);
            console.log("user.totalWithdrawl", user.totalWithdrawal);
            console.log("user.referralWallet", user.referralWallet);
            console.log("user.totalPenalty", user.totalPenalty);
            let calculatedWallet =
              user.wonAmount -
              user.loseAmount +
              user.totalDeposit +
              user.referralEarning +
              user.holdBalance +
              user.totalBonus -
              (user.totalWithdrawal + user.referralWallet + user.totalPenalty);
            console.log("calculated wallet balance:-", calculatedWallet);
            console.log("api wallet balance is:-", user.walletBalance);
            // calculatedWallet == user.walletBalance;
            //   ? doAutoPayout()
            //   : withReqComes();
            withReqComes();
          } else {
            // setMount(false);
            // setSubmitBtn(true);
            ToastError(res.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status == 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("token");
            window.location.reload();
            navigate("/login");
          }
        });
    } else {
      console.log("enter details are not corerct");
    }
  };

  const updateWallet = () => {
    console.log("wallet update is triggered");
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        dispatch({ type: "USER_DATA", data: res.data });
        // setUser(res.data);
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          localStorage.removeItem("token");
        }
      });
  };

  const withReqComes = async () => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      console.log("at withReqComes function:-amount is ;-", amount);
      await axios
        .post(
          baseUrl + `withdraw/request`,
          {
            amount,
            type: "manualupi",
            paymentGateway: "manualupi",
          },
          { headers }
        )
        .then((res) => {
          console.log("manual request for payment res :-", res);
          updateWallet();
          if (res.data.success) {
            ToastError(res.data.msg);
          } else {
            ToastError(res.data.msg);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="withdraw-payment-form-container">
      <div className="withdraw-form-title">Payment Details</div>
      <form
        className="withdraw-payment-form"
        onSubmit={(e) => {
          e.preventDefault();
          console.log("Submit is clicked");
          console.log("name of ac holder", holderName);
          console.log("Ac no is:-", accountNumber);
          console.log("ifcs code :-", ifscCode);
          updateBankDetails(e);
        }}
      >
        <div className="withdraw-user-input-div">
          <label>
            <span>
              <AccountBalanceIcon sx={{ fontSize: 16 }} />
            </span>
            <span>Account holder name</span>
          </label>
          <input
            type="text"
            placeholder="Account holder name"
            required
            value={holderName}
            onChange={(e) => setHolder_name(e.target.value)}
          />
        </div>

        <div className="withdraw-user-input-div">
          <label>
            <span>
              {" "}
              <AccountBalanceIcon sx={{ fontSize: 16 }} />
            </span>
            <span>Account number</span>
          </label>
          <input
            type="number"
            placeholder="Account number"
            required
            value={accountNumber}
            onChange={(e) => setAccount_number(e.target.value)}
          />
        </div>

        <div className="withdraw-user-input-div">
          <label>
            <span>
              {" "}
              <AccountBalanceIcon sx={{ fontSize: 16 }} />
            </span>
            <span>IFSC code</span>
          </label>
          <input
            type="text"
            placeholder="IFSC code"
            required
            value={ifscCode}
            onChange={(e) => setIfsc_code(e.target.value)}
          />
        </div>

        <div className="withdraw-user-input-div withdraw-chips-input-div">
          <label>
            <span>
              {" "}
              <AccountBalanceWalletIcon sx={{ fontSize: 16 }} />
            </span>
            <span>Chips</span>
          </label>
          <input
            type="number"
            placeholder="Chips to withdraw"
            min={200}
            max={10000}
            defaultValue=""
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <p className="withdraw-legal-terms-container">
          By Continuing, you agree to our{" "}
          <Link to="/term-conditions">Legal Terms</Link> and you are 18 years or
          older.
        </p>
        <button className="withdraw-payment-submit-button" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default WithdrawForm;
