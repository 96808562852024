import React from "react";
import "./RunningBattle.css";
// import battleIcon from "../../../../assets/images/battleIcon.png";
import battleIcon from "../../../../assets/images/fight-svgrepo-com.svg";
import RunningBattleCard from "./running_battle_card/RunningBattleCard";
import RunningBattleStaticCard from "./running_battle_static_card/RunningBattleStaticCard";

const RunningBattle = ({
  user,
  game_type,
  ownRunning,
  runningGames,
  staticRunningBets,
  winamountFun,
  generateRandomString,
}) => {
  // let data1 = {
  //   userName1: "perGta",
  //   userName2: "cbGtrs",
  //   entryFee: 50,
  //   prize: 50 * 2 - 50 * 0.05,
  // };
  // console.log("Data of own running:--", ownRunning);
  // console.log("Data of runningGames:---", runningGames);
  // console.log(game_type);
  return (
    <>
      <div className="running-battle-heading">
        <img width="24px" height="24px" src={battleIcon} />
        Running Battles
      </div>

      {/* own running bets */}
      {/* Check start */}

      {/* Check end */}
      {/* running games ... */}
      {ownRunning &&
        ownRunning?.map((runnig) => {
          if (
            (user == runnig.acceptedBy._id || user == runnig.createdBy._id
              ? user == runnig.acceptedBy._id
                ? (runnig.status === "running" &&
                    user == runnig.acceptedBy._id &&
                    runnig.acceptorSeen == true) ||
                  (runnig.status === "pending" && runnig.acceptorStatus == null)
                : (runnig.status === "running" &&
                    user == runnig.createdBy._id) ||
                  (runnig.status === "pending" && runnig.creatorStatus == null)
              : runnig.status === "running" || runnig.status === "pending") &&
            runnig.gameType == game_type
          )
            return (
              <RunningBattleCard
                key={runnig._id}
                runnig={runnig}
                user={user}
                winamountFun={winamountFun}
              />
            );
        })}

      {/* runningGames */}
      {runningGames &&
        runningGames?.map((runnig) => {
          const isUserAccepted = user === runnig.acceptedBy._id;
          const isUserCreator = user === runnig.createdBy._id;
          const isUserConflicted =
            (isUserAccepted && runnig.status === "conflict") ||
            (isUserCreator && runnig.status === "conflict");

          if (user === runnig?.acceptedBy._id) {
            console.log("in acceptor block");
            if (
              (runnig?.status === "running" && runnig?.acceptorSeen) ||
              runnig.status === "pending" ||
              runnig.status === "conflict" ||
              isUserConflicted
            ) {
              console.log("running game", runnig);
              if (runnig.gameType === game_type) {
                return (
                  <RunningBattleCard
                    key={runnig._id}
                    runnig={runnig}
                    user={user}
                    winamountFun={winamountFun}
                  />
                );
              }
            }
          } else {
            if (
              (runnig.status === "running" && user === runnig.createdBy._id) ||
              runnig.status === "pending" ||
              runnig.status === "conflict" ||
              isUserConflicted
            ) {
              if (runnig.gameType === game_type) {
                return (
                  <RunningBattleCard
                    key={runnig._id}
                    runnig={runnig}
                    user={user}
                    winamountFun={winamountFun}
                  />
                );
              }
            }
          }
        })}

      {/* static running bets... */}

      {staticRunningBets.map((val, index) => {
        let mappedStaticBetData = {
          userName1: val.playerName,
          userName2: val.playerName,
          entryFee: val.amount,
          prize: val.winAmount,
        };
        return (
          <RunningBattleStaticCard
            data={mappedStaticBetData}
            key={index}
            generateRandomString={generateRandomString}
          />
        );
      })}
    </>
  );
};

export default RunningBattle;
