import React from "react";
import "./BottomInfoCard.css";

const BottomInfoCard = () => {
  return (
    <div className="bottom-info-container">
      <p>
        यह LudoClassicManual है यहा आप SET ROOM CODE मे दुसरे गेम के कोड देते है
        या शेयर करते है तो टेबल कैंसल कर दिया जाएगा और Penalty लगा दी जाएगी|
      </p>
    </div>
  );
};

export default BottomInfoCard;
