import React, { useContext, useEffect, useRef, useState } from "react";
import RoomCodeTopButton from "./room_code_top_button/RoomCodeTopButton";
import "./RoomCodeData.css";
import RoomCodeHeader from "./room_code_header/RoomCodeHeader";
import RoomCodeBottom from "./room-code-bottom/RoomCodeBottom";
import RoomCodeMiddle from "./room_code_middle/RoomCodeMiddle";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastError } from "../../../helper/tost_helper/ToastError";
import axiosHelper from "../../../api's/axiosHelper";
import DataContext from "../../../context/DataContext";
import axios from "axios";
import WinCard from "./win_card/WinCard";
import LostCard from "./lost_card/LostCard";
import CancleCard from "./cancle_card/CancleCard";

const RoomCodeData = (props) => {
  // var websocketURL = process.env.REACT_APP_SOCKET_API;
  // const history = useHistory();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  var websocketURL = process.env.REACT_APP_SOCKET_API;

  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const { state } = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split("/")[2];

  const [Game, setGame] = useState();
  const [status, setStatus] = useState(null);
  const [fecthStatus, setFecthStatus] = useState();
  const [scrnshot, setScrnshot] = useState(null);
  // const [scrnshot1, setScrnshot1] = useState(""); // ADDED BY TEAM

  const [reason, setReason] = useState(null);
  const [socket, setSocket] = useState();
  const [roomcode, setRoomcode] = useState("");
  let submitReq = useRef(false);
  const isMounted = useRef(true);

  const [submitProcess, setProcess] = useState(true);
  const [loading, setLoading] = useState(false);

  // To check the code of ludo king's
  const getPost = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    setLoading(true);
    await axiosHelper
      .patch(
        `challange/roomcode/${path}`,
        {
          roomCode: roomcode,
        },
        { headers }
      )
      .then((res) => {
        setLoading(false);
        if (res.data === "Room code dose not match for ludo classic game") {
          ToastError(res.data);
          return;
        } else {
          // setGame(res.data);
          setGame({ ...Game, roomCode: roomcode });
          socket.emit("challengeOngoing");
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          navigate("/login");
        } else {
          ToastError("Error occured!!!");
          return;
        }
      });
  };

  const [user, setUser] = useState();
  const [userAllData, setUserAllData] = useState();

  const role = async () => {
    console.log("User data is :- ", state.user_data);
    setUser(state.user_data._id);
    setUserAllData(state.user_data);
    Allgames(state.user_data._id);
    getCode(state.user_data._id);
  };

  const [ALL, setALL] = useState();

  const Allgames = async (userId) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    await axiosHelper
      .get(`challange/${path}`, { headers })
      .then((res) => {
        if (res.data.status == "new" || res.data.status == "requested") {
          setTimeout(async () => {
            await axiosHelper
              .get(`challange/${path}`, { headers })
              .then((res) => {
                if (
                  res.data.status == "new" ||
                  res.data.status == "requested"
                ) {
                  navigate(props.location.state.prevPath);
                } else {
                  setProcess(false);
                }
              })
              .catch((error) => {
                console.error(error);
                navigate(props.location.state.prevPath);
              });
          }, 10000);
        } else {
          setProcess(false);
        }
        setALL(res.data);
        setGame(res.data);
        // // console.log(res.data.acceptedBy._id)
        if (userId == res.data.acceptedBy._id)
          setFecthStatus(res.data.acceptorStatus);

        if (userId == res.data.createdBy._id)
          setFecthStatus(res.data.creatorStatus);
      })
      .catch((e) => {
        console.log("All games error", e);
        if (e?.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          navigate("/login");
        }
      });
  };

  const getCode = async (userId) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axiosHelper
      .get(`game/roomcode/get/${path}`, { headers })
      .then((res) => {
        //setALL(res.data)
        Allgames(userId);
        // if (res.data.acceptedBy == userId && res.data.roomCode == 0) {
        //   setTimeout(async () => {
        //     getCode();
        //   }, 10000);
        // }
      });
  };

  useEffect(() => {
    WebSocket.prototype.emit = function (event, data) {
      if (this.readyState === WebSocket.OPEN)
        this.send(JSON.stringify({ event, data }));
    };
    WebSocket.prototype.listen = function (eventName, callback) {
      this._socketListeners = this._socketListeners || {};
      this._socketListeners[eventName] = callback;
    };
    //let socket = new WebSocket("ws://192.168.29.119:5001/server");
    let socket = new WebSocket(websocketURL);
    function openFunc() {
      socket.onopen = () => {
        console.log("websocket is connected 👍");
        setSocket(socket);
        socket.pingTimeout = setTimeout(() => {
          socket.close();
          setSocket(undefined);
        }, 30000 + 1000);
      };
    }

    function listenFunc() {
      socket.onmessage = function (e) {
        try {
          const { event, data } = JSON.parse(e.data);
          if (socket._socketListeners[event])
            socket._socketListeners[event](data);
        } catch (error) {
          console.log(error);
        }
      };
      socket.listen("ping", (data) => {
        socket.emit("pong", 2);
        clearTimeout(socket.pingTimeout);
        socket.pingTimeout = setTimeout(() => {
          socket.close();
          setSocket(undefined);
        }, 30000 + 1000);
      });
      socket.listen("ongoingChallenge", (data) => {
        getCode(user);
      });
      socket.listen("socketUpdate", (data) => {
        Allgames(user);
      });
    }
    function closeFunc() {
      socket.onclose = () => {
        console.log("socket disconnected wow 😡");
        if (isMounted.current) {
          clearTimeout(socket.pingTimeout);
          setSocket(undefined);
          let socket = new WebSocket(websocketURL);
          //socket = new WebSocket("ws://192.168.29.119:5001/server");
          openFunc();
          listenFunc();
          closeFunc();
        }
      };
    }
    openFunc();
    listenFunc();
    closeFunc();
    return () => {
      isMounted.current = false;
      clearTimeout(socket.pingTimeout);
      setSocket(undefined);
      socket.close();
    };
  }, []);

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      navigate("/login");
    }
    // console.log(history.location)
    role();
  }, []);

  // const clearImage = (e) => {
  //   setScrnshot1(null);
  //   setScrnshot(null);
  //   setStatus(null);
  //   // change the id here...............................................................................
  //   const modalCloseButton = document.querySelector(
  //     '[data-bs-dismiss="modal"]'
  //   );
  //   if (modalCloseButton) {
  //     modalCloseButton.click();
  //   }
  // };

  const Result = async (e) => {
    console.log("Result is triggered...");
    console.log("status is:- ", status);
    e.preventDefault();

    if (submitReq.current == false) {
      submitReq.current = true;
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      if (status) {
        setProcess(true);
        const formData = new FormData();
        formData.append("file", scrnshot);
        formData.append("status", status);
        if (status === "cancelled") {
          formData.append("reason", reason);
        }

        console.log("formData is :- file ", formData.get("file"));
        console.log("formData is :- status ", formData.get("status"));
        console.log("formData is :- reason ", formData.get("reason"));

        console.log("path for request is :- ", `challange/result/${path}`);
        console.log("FormData is :- ", formData);
        console.log("Header is :- ", headers);
        // await axiosHelper
        //   .post(`challange/result/${path}`, {
        //     data: formData,
        //     headers: headers,
        //   })

        await axios({
          method: "post",
          url: baseUrl + `challange/result/${path}`,
          data: formData,
          headers: headers,
        })
          .then((res) => {
            console.log("Response is :- ", res);
            setStatus(null);
            ToastError("Successfully done");
            //  socket.emit('resultAPI');

            // submitReq.current = false;
            // setProcess(false);
            socket.emit("socketCall");
            // history.push(props.location.state.prevPath);
            // window.location.href = props.location.state.prevPath;
            navigate("/");
          })
          .catch((e) => {
            console.log(e);
            ToastError("Error occured!!!");
            if (e.response?.status == 401) {
              console.log(e.response);
              localStorage.removeItem("token");
              localStorage.removeItem("token");
              window.location.reload();
              navigate("/login");
            }
          });
      } else {
        submitReq.current = false;
        alert("please fill all field or Re-Select result status");
      }
    }
  };

  // const copyCode = (e) => {
  //   // console.log(Game.roomCode);
  //   navigator.clipboard.writeText(Game.roomCode);
  // };

  const Completionist = () => <span>You are good to go!</span>;

  // const handleChange = (e) => {
  //   setScrnshot1(URL.createObjectURL(e.target.files[0]));
  //   setScrnshot(e.target.files[0]);
  // };

  const [isChipClicked, setIsChipClicked] = useState(false);

  const handleChipClick = () => {
    setIsChipClicked(!isChipClicked);
  };
  const [isClicked, setIsClicked] = useState(false);

  const handleReasonClick = (selectedReason) => {
    setReason(selectedReason);
    setIsClicked(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleButtonClick = () => {
    if (isChecked) {
      setStatus("lose");
    } else {
    }
  };

  return (
    <>
      <div className="room-code-data-container">
        <RoomCodeTopButton />;
        <RoomCodeHeader Game={Game} />
        <RoomCodeMiddle
          Game={Game}
          user={user}
          roomcode={roomcode}
          setRoomcode={setRoomcode}
          loading={loading}
          getPost={getPost}
        />
        <RoomCodeBottom
          status={status}
          setStatus={setStatus}
          Game={Game}
          fecthStatus={fecthStatus}
        />
      </div>
      {status === "win" && (
        <WinCard
          status={status}
          setStatus={setStatus}
          // handleChange={handleChange}
          scrnshot={scrnshot}
          setScrnshot={setScrnshot}
          // scrnshot1={scrnshot1}
          // clearImage={clearImage}
          Result={Result}
          // global_cross={global_cross}
        />
      )}

      {status === "lose" && <LostCard setState={setStatus} Result={Result} />}
      {status === "cancelled" && (
        <CancleCard
          reason={reason}
          setReason={setReason}
          Result={Result}
          setStatus={setStatus}
        />
      )}
    </>
  );
};

export default RoomCodeData;
