import React from "react";
import "./AddCashMessage.css";

const AddCashMessage = () => {
  return (
    <>
      <div className="add-cash-message-container">
        <p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width={20}
            height={20}
            fill="red"
          >
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </svg>{" "}
          &nbsp;&nbsp;यदि आप किसी भी पेमेंट का बैंक से Complain डालते है तो आपके
          खाते को Block कर दिया जायेगा ! इसलिए किसी और से अपने Ludo ID में पैसे
          न डलवाये ! और यदि आप खुद जान भूझकर बैंक से रिफंड लेने के लिए Complain
          डालते है तो आपकी Ludo ID पूर्णतः बंद कर दी जाएगी !
        </p>
      </div>
    </>
  );
};

export default AddCashMessage;
