import React from 'react'
import "./Rightside.css"
import kd_right from "../../assets/images/logo.jpg"
const Rightside = () => {
  return (
    <div className='rightContainer'>
      <div className='sub-rightContainer'>
        <img width="200px" height="200px"src={kd_right} />
        <h1>PLAY LUDO & Win Real Cash !</h1>
        <p>For best experience, open <span>KD124</span> on  <span>Chrome mobile</span></p>
        </div>
       
    </div>
  )
}

export default Rightside