import React, { useContext, useEffect, useRef, useState } from "react";
import "./LoginForm.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastError } from "../../../helper/tost_helper/ToastError";
import ResendButton from "./resend_button/ResendButton";
import LoginFormHeading from "./login_form_heading/LoginFormHeading";
import AxiosPost from "../../../api's/AxiosPost";
import DataContext from "../../../context/DataContext";

const LoginForm = () => {
  let navigate = useNavigate();
  // const { dispatch } = useContext(DataContext);
  console.log("Login form triggered..");

  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [secretCode, setSecretCode] = useState("");
  const user_phone_number_inputRef = useRef();
  const user_otp_inputRef = useRef();
  // const [referral, setReferral] = useState("Ludo Classics");
  const [referral, setReferral] = useState(
    useLocation().pathname.split("/")[2]
  );

  const sendOTPhandler = async (e) => {
    e.preventDefault();
    let enteredNumber = user_phone_number_inputRef.current.value;

    if (enteredNumber.length !== 10 || !Number(enteredNumber)) {
      ToastError("Enter a Valid Mobile Number", 3000);
    } else {
      setIsLoading(true);

      let response = await AxiosPost("login", {
        phone: enteredNumber,
        referral: referral,
      });

      if (response) {
        setIsOTPSent(true);
        setSecretCode(response.data.secret);
      }
      console.log(response);
      setIsLoading(false);
    }
  };

  const verifyOTPHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let enterdOTP = user_otp_inputRef.current.value;
    let enteredNumber = user_phone_number_inputRef.current.value;
    let data = {
      phone: enteredNumber,
      twofactor_code: enterdOTP,
      referral: referral,
      secretCode: secretCode,
    };
    console.log(data);

    let response = await AxiosPost("login/finish", data);
    console.log(response);
    if (response) {
      const token = response.data.token;
      localStorage.setItem("token", token);
      // dispatch({ type: "AUTH_SUCCESS" });
      // setTimeout(() => {
      window.location.href = "/";
      // }, 1000);
      //
    }
    setIsLoading(false);
  };

  return (
    <div className="loginform-container">
      <LoginFormHeading />
      <form>
        <div>
          <label>Enter Mobile Number</label>
        </div>

        <input
          className="enter-phone-number-input"
          type="text"
          placeholder="Enter mobile number"
          maxLength="10"
          ref={user_phone_number_inputRef}
        />

        <p>
          By Continuing, you agree to our{" "}
          <Link to="/term-conditions">Legal Terms</Link> and you are 18 years or
          older.
        </p>

        {isOTPSent && !isLoading ? (
          <input
            className="enter-otp-input"
            type="text"
            placeholder="Enter OTP"
            maxLength="6"
            ref={user_otp_inputRef}
          />
        ) : (
          ""
        )}

        {!isOTPSent && !isLoading ? (
          <button className="send-otp-button" onClick={sendOTPhandler}>
            Send OTP
          </button>
        ) : isLoading ? (
          <button disabled className="loading-button">
            Loading...
          </button>
        ) : (
          <>
            <ResendButton count={30} sendOTPhandler={sendOTPhandler} />
            <button className="verify-button" onClick={verifyOTPHandler}>
              {" "}
              Verify
            </button>{" "}
          </>
        )}
      </form>
    </div>
  );
};

export default LoginForm;
