import React, { useState } from "react";
import "./HistoryPaginate.css";

const HistoryPaginate = ({ currentPage, totalPageNo, setPageNo }) => {
  console.log("At the paginate:-");
  console.log("Current page:-", currentPage);
  console.log("total pages;-", totalPageNo);

  const incCount = () => {
    setPageNo(currentPage + 1);
  };

  const decCount = () => {
    setPageNo(currentPage - 1);
  };
  return (
    <>
      <div className="paginate-container">
        {currentPage === 1 ? "" : <button onClick={decCount}> Prev </button>}

        <div className="paginate-count-container">
          {currentPage === 1 ? (
            ""
          ) : (
            <>
              <div className="pagination-prev-page">1</div>
              <div>..</div>
            </>
          )}

          <div className="pagination-current-page">{currentPage}</div>

          {currentPage === totalPageNo ? (
            ""
          ) : (
            <>
              <div>..</div>
              <div className="pagination-final-page">{totalPageNo}</div>
            </>
          )}
        </div>
        {currentPage === totalPageNo ? (
          ""
        ) : (
          <button onClick={incCount}> Next </button>
        )}
      </div>
    </>
  );
};

export default HistoryPaginate;
