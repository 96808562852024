import React from 'react'

const CancellationPolicy = () => {
  return (
    <div className='terms-conditions-content'>
    
                  <span style={{ fontWeight: "900", fontFamily: "Poppins" }}>
                    {" "}
                    Refund Policy
                  </span>{" "}
                  <br />
                  <br />
                  Thanks for being connected with{" "}
                  <span
                    style={{
                      fontWeight: "900",
                      fontFamily: "Poppins",
                      color: "#DC3545",
                    }}
                  >
                    {" "}
                    KD 124
                  </span>
                  . If you are not entirely satisfied with your subscription, we
                  are here to help.
                  <br />
                  <br />{" "}
                  <span style={{ fontWeight: "900", fontFamily: "Poppins" }}>
                    {" "}
                    Refunds Process
                  </span>{" "}
                  <br />
                  <br /> Once we receive your Refund request, we will inspect it
                  and notify you on the status of your refund. <br />
                  <br />
                  If your refund request is approved, we will initiate a refund
                  to your credit card (or original method of payment) within 7
                  working days. You will receive the credit within a certain
                  amount of days, depending on your card issuer's policies.{" "}
                  <br />
                  <br />
                  In case of unforeseen technical glitch,
                  <span style={{ fontFamily: "Poppins", color: "#DC3545" }}>
                    {" "}
                    KD 124
                  </span>{" "}
                  would refund subscription upon reviewing the complaint. Final decision lies with the company.
               
    </div>
  )
}

export default CancellationPolicy