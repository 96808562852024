import { toast } from "react-toastify";

export function ToastError(message, delay = 2000) {
  toast.error(message, {
    position: "top-right",
    autoClose: delay,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    // transition: Bounce,
  });
}
