import React, { createContext, useReducer } from "react";

const HistoryContext = createContext();

const reducerFun = (state, action) => {
  // console.log("History reducer:- ");
  // console.log(action);
  if (action.type === "DEPOSIT_HISTORY") {
    return { ...state, depositHistory: { ...action.data } };
  } else if (action.type === "GAME_HISTORY") {
    return { ...state, gameHistory: { ...action.data } };
  } else if (action.type === "REFERRAL_HISTORY") {
    return { ...state, referralHistory: { ...action.data } };
  }
  if (action.type === "SHOW_ALL") {
    console.log("Show all clicked..")
    return {
      ...state,
      showAll: true,
      showGame: false,
      showWintdraw: false,
      showDeposit: false,
      showReferral: false,
      showBonus: false,
      showPenalty: false,
    }
  }
  else if (action.type === "SHOW_GAME") {
    console.log("Show games clicked..")
    return {
      ...state,
      showAll: false,
      showGame: true,
      showWintdraw: false,
      showDeposit: false,
      showReferral: false,
      showBonus: false,
      showPenalty: false,
    }
  }
  else if (action.type === "SHOW_WITHDRAW") {
    console.log("Show withdraw clicked..")
    return {
      ...state,
      showAll: false,
      showGame: false,
      showWintdraw: true,
      showDeposit: false,
      showReferral: false,
      showBonus: false,
      showPenalty: false,
    }

  }

  else if (action.type === "SHOW_DEPOSIT") {
    console.log("Show deposite clicked..")
    return {
      ...state,
      showAll: false,
      showGame: false,
      showWintdraw: false,
      showDeposit: true,
      showReferral: false,
      showBonus: false,
      showPenalty: false,
    }

  }
  else if (action.type === "SHOW_REFERRAL") {
    console.log("Show referral clicked..")
    return {
      ...state,
      showAll: false,
      showGame: false,
      showWintdraw: false,
      showDeposit: false,
      showReferral: true,
      showBonus: false,
      showPenalty: false,
    }
  }

  else if (action.type === "SHOW_BONUS") {
    console.log("Show bonus clicked..")
    return {
      ...state,
      showAll: false,
      showGame: false,
      showWintdraw: false,
      showDeposit: false,
      showReferral: false,
      showBonus: true,
      showPenalty: false,
    }

  }
  else if (action.type === "SHOW_PENALTY") {
    console.log("Show penality clicked..")
    return {
      ...state,
      showAll: false,
      showGame: false,
      showWintdraw: false,
      showDeposit: false,
      showReferral: false,
      showBonus: false,
      showPenalty: true,
    }
  }

  // else if (action.type === "TOTAL_PENALTY") {
  //   return {
  //     ...state,
  //     totalPenalty: state.totalPenalty + action.data
  //   }
  // }


  return state;
};
export const HistoryDataProvider = ({ children }) => {
  const initial_state = {
    gameHistory: {},
    depositHistory: {},
    referralHistory: {},
    showAll: true,
    showGame: false,
    showWintdraw: false,
    showDeposit: false,
    showReferral: false,
    showBonus: false,
    showPenalty: false,
    // totalPenalty: 0,
  };

  const [state, dispatch] = useReducer(reducerFun, initial_state);
  return (
    <HistoryContext.Provider
      value={{ historyState: state, historyDispatch: dispatch }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export default HistoryContext;
