import React from "react";
import "./RoomCodeHeader.css";
import user1Avtar from "../../../../assets/images/avtar/user.png";
import vsImg from "../../../../assets/images/versus1.png";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const RoomCodeHeader = ({ Game }) => {
  return (
    <div className="room-code-header-container">
      <div className="room-code-users-container">
        <div className="room-code-user1">
          <div className="room-code-user1-name">
            {Game?.createdBy && Game?.createdBy?.name}
          </div>
          <img
            width="60px"
            className="room-code-user1-img"
            src={user1Avtar}
            alt=""
          />
        </div>

        <div className="room-codem-vsimg">
          <img width="50px" height="50px" src={vsImg} alt="" />
        </div>

        <div className="room-code-user2">
          <div className="room-code-user2-name">
            {Game?.acceptedBy && Game?.acceptedBy?.name}
          </div>
          <img
            width="60px"
            className="room-code-user2-img"
            src={user1Avtar}
            alt=""
          />
        </div>
      </div>
      <div className="room-code-playingfor">
        <span>Playing For</span>
        <span>
          <CurrencyRupeeIcon sx={{ fontSize: 22, color: "green" }} />
          {Game?.gameAmount}
        </span>
      </div>
    </div>
  );
};

export default RoomCodeHeader;
