import React, { useRef, useState } from "react";
import "./WinCard.css";
// import global_cross from "../../../../assets/images/icons/global-cross.png";

const WinCard = ({
  status,
  setStatus,
  // handleChange,
  scrnshot,
  setScrnshot,
  // clearImage,
  // scrnshot1,
  Result,
}) => {
  let inputRef = useRef();
  const [scrnshot1, setScrnshot1] = useState("");

  // const clearImage = (e) => {
  //   setScrnshot1(null);
  //   setScrnshot(null);
  //   // setStatus(null);
  //   // const modalCloseButton = document.querySelector(
  //   //   '[data-bs-dismiss="modal"]'
  //   // );
  //   // if (modalCloseButton) {
  //   //   modalCloseButton.click();
  //   // }
  // };

  const handleChange = (e) => {
    setScrnshot1(URL.createObjectURL(e.target.files[0]));
    setScrnshot(e.target.files[0]);
  };

  return (
    <>
      <div className="win-card-container">
        <div className="win-card-close-button">
          <button
            onClick={() => {
              setStatus(null);
              setScrnshot(null);
            }}
          >
            X
          </button>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            Result(e);
          }}
          encType="multipart/form-data"
        >
          <div className="win-card-details">
            <div className="win-card-title"> Upload Result</div>
            {/* <div className="win-card-input"> */}
            {/* <input type="file" accept="image/*" required /> */}
            {status !== null && status !== "cancelled" && status !== "lose" && (
              <div className="win-card-input-div">
                <input
                  type="file"
                  onChange={handleChange}
                  ref={inputRef}
                  accept="image/*"
                  required
                />
                {/* {!scrnshot && (
                  <div>
                    <div accordion>Upload screenshot.</div>
                  </div>
                )} */}
                {scrnshot && (
                  <div className="win-card-img-details">
                    <div className="win-card-text">
                      <div>{scrnshot.name}</div>
                      <div className="win-card-file-size">
                        {(scrnshot.size / 1024 / 1024).toFixed(2)} MB
                      </div>
                    </div>
                    {/* <div className="image-block">
                    <img
                      src={global_cross}
                      width="10px"
                      alt=""
                      onClick={clearImage}
                    />
                  </div> */}
                  </div>
                )}
              </div>
            )}
            {scrnshot1 ? (
              <div className="win-upload-img-container">
                <img
                  max-width="100px"
                  height="200px"
                  src={scrnshot1}
                  alt="screenshot1"
                />
                <div
                  className="img-clear-button"
                  onClick={() => {
                    setScrnshot(null);
                    setScrnshot1("");
                    inputRef.current.value = "";
                  }}
                >
                  X
                </div>
              </div>
            ) : null}
            {/* eennddd */}
            {/* </div> */}
            <div className="win-card-post-button">
              <button
                type="submit"
                id="post"
                // onSubmit={(e) => {
                //   e.preventDefault();
                //   console.log("Posted...clicked...");
                //   Result();
                // }}
              >
                POST SUBMIT
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default WinCard;
