import React, { useContext, useEffect, useRef, useState } from "react";
import "./KycForm.css";
import KycFormHeader from "./kyc_form_header/KycFormHeader";
import { Link, useNavigate } from "react-router-dom";
import DataContext from "../../../context/DataContext";
import axiosHelper from "../../../api's/axiosHelper";
import { ToastError } from "../../../helper/tost_helper/ToastError";
const KycForm = () => {
  const { state } = useContext(DataContext);

  let navigate = useNavigate();
  const [user, setUser] = useState(state.user_data);
  const [frontLoaded, setfrontLoaded] = useState(null);
  const [backLoaded, setbackLoaded] = useState(null);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [DOB, setDob] = useState();
  const [process, setProcess] = useState(false);
  let aadharProcess = useRef(false);

  const handleSubmitdata = async (e) => {
    e.preventDefault();

    if (name?.length > 20) {
      ToastError("Name should not be longer than 20 characters");
      return;
    } else if (name?.length < 1) {
      ToastError("Name should't be less than 1 characters");
      return;
    }

    if (user.verified == "unverified") {
      if (aadharProcess.current === false) {
        setProcess(true);
        aadharProcess.current = true;
        e.preventDefault();
        console.log(frontLoaded, backLoaded);
        const formData = new FormData();

        formData.append("name", name);
        formData.append("email", email);
        formData.append("number", number);
        formData.append("dob", DOB);
        formData.append("front", frontLoaded);
        formData.append("back", backLoaded);

        if (frontLoaded && backLoaded) {
          const access_token = localStorage.getItem("token");
          const headers = {
            Authorization: `Bearer ${access_token}`,
          };
          console.log("formdata", formData);

          await axiosHelper
            .post(`aadharcard`, formData, { headers })
            .then((res) => {
              console.log(res.data);

              if (res.data.msg === false) {
                ToastError("Duplicate Entity");
              } else {
                ToastError("You Kyc form submitted");

                navigate("/Profile");
              }

              aadharProcess.current = false;
              setProcess(false);
            })
            .catch((e) => {
              if (e.response.status == 400) {
                ToastError(`${e.response.data.msg}`);
                aadharProcess.current = false;
                setProcess(false);
                return;
              }
              if (e.response.status == 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("token");
                window.location.reload();
                navigate("/login");
              }
            });
        } else {
          aadharProcess.current = false;
          setProcess(false);
          alert("please fill all field");
        }
      } else {
        alert("You have submitted request already.");
      }
    } else {
      alert("You request in Process.");
    }
  };

  useEffect(() => {
    console.log(user);
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      navigate("/login");
    }
  }, []);

  return (
    <>
      <KycFormHeader />
      <div className="kyc-form-container">
        <form onSubmit={handleSubmitdata} className="kyc-form">
          <div className="kyc-form-heading">KYC</div>

          <div className="kyc-form-input-div">
            <label>Enter Name</label>
            <input
              title="Name should not be longer than 20 characters"
              // maxlength="20"
              className="kyc-form-input"
              type="text"
              placeholder="Enter name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="kyc-form-input-div">
            <label>Enter Aadhar Number</label>
            <input
              className="kyc-form-input"
              type="text"
              placeholder="Enter name"
              required
              maxLength={12}
              onChange={(e) => setNumber(e.target.value)}
            />
          </div>

          <div className="kyc-form-input-div">
            <label>Enter Your Email</label>
            <input
              className="kyc-form-input"
              type="text"
              placeholder="Enter name"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="kyc-form-input-div">
            <label>Front Image</label>
            <input
              className="aadharcard"
              type="file"
              placeholder="Enter name"
              required
              onChange={(e) => setfrontLoaded(e.target.files[0])}
            />
          </div>

          <div className="kyc-form-input-div">
            <label>Back Image</label>
            <input
              className="aadharcard"
              type="file"
              placeholder="Enter name"
              required
              onChange={(e) => setbackLoaded(e.target.files[0])}
            />
          </div>
          <p className="kyc-form-terms">
            By Continuing, you agree to our{" "}
            <Link to="/term-conditions">Legal Terms</Link> and you are 18 years
            or older.
          </p>
          <button type="submit" className="kyc-form-submit-button">
            {process ? "Loading..." : "Request for KYC"}
          </button>
        </form>
      </div>
    </>
  );
};

export default KycForm;
