import React, { useContext } from "react";
import "./TopButtons.css";
import DataContext from "../../../../context/DataContext";
import { Link } from "react-router-dom";
import ArrowDropDownCircleTwoToneIcon from "@mui/icons-material/ArrowDropDownCircleTwoTone";

const TopButtons = () => {
  const { state, dispatch } = useContext(DataContext);
  return (
    <>
      <div className="withdraw-cash-heading">
        <Link to="/wallet">
          <button className="withdraw-cash-back-button">Back</button>
        </Link>

        <button className="withdraw-cash-heading-button">
          <div
            className="withdraw-cash-guide-button"
            onClick={() => dispatch({ type: "CLICKED_FOR_GUIDE" })}
          >
            <ArrowDropDownCircleTwoToneIcon sx={{ color: "white" }} />
            Guide
          </div>
        </button>
      </div>
    </>
  );
};

export default TopButtons;
