import React, { useState } from 'react'
import "./TermsConditions.css"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TermsConditionsData from './TermsConditionsData';
import CancellationPolicy from './CancellationPolicy';
import PrivacyPolicy from './PrivacyPolicy';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import Navbar from '../../components/navbar/Navbar';
import Rightside from '../../components/rightside/Rightside';

const TermsConditions = () => {
  const [tabData, setTabData] = useState({
    terms:false,
    cancellation: false,
    privacy: false,
    aboutus:false,
    contactus:false,

  });
  

  
  return ( 
    <>
    <Navbar/>
    <div className='terms-container'>
       <div className='terms-sub-container'>

        <div className='terms-conditions-item'>
          <div className={ tabData.terms ? 'terms-conditions-heading term-tab-clicked' : 'terms-conditions-heading' }  onClick={ ()=>
          setTabData((tabData) => {
            return {...tabData, terms:!tabData.terms}
          })
        }>
          <div >Terms & Conditions</div>
          <div><KeyboardArrowDownIcon/>
          </div>  
          </div>
          <div>{tabData.terms ? <TermsConditionsData/> :""}</div>
        </div>

        <div className='terms-conditions-item' >
        <div className={ tabData.cancellation ? 'terms-conditions-heading term-tab-clicked' : 'terms-conditions-heading' } onClick={
          ()=>{
            setTabData((tabData) => {
              return {...tabData, cancellation:!tabData.cancellation}
            })
          }
        }>
        <div>Cancellation & Refund Policy</div>
        <div><KeyboardArrowDownIcon/></div>
        </div>
        {tabData.cancellation ? <CancellationPolicy/> :""}
        </div>

        <div className='terms-conditions-item' >
        <div className={ tabData.privacy ? 'terms-conditions-heading term-tab-clicked' : 'terms-conditions-heading' } onClick={
          ()=>{
            setTabData((tabData) => {
              return {...tabData, privacy:!tabData.privacy}
            })
          }
        }>
        <div>Privacy Policy</div>
        <div><KeyboardArrowDownIcon/></div>
        </div>
        {tabData.privacy ? <PrivacyPolicy/> :""}
        </div>

        <div className='terms-conditions-item' >
        <div className={ tabData.aboutus ? 'terms-conditions-heading term-tab-clicked' : 'terms-conditions-heading' } onClick={
          ()=>{
            setTabData((tabData) => {
              return {...tabData, aboutus:!tabData.aboutus}
            })
          }
        }>
        <div>About Us</div>
        <div><KeyboardArrowDownIcon/></div>
        </div>
        {tabData.aboutus ? <AboutUs/> :""}
        </div>

        <div className='terms-conditions-item' >
        <div className={ tabData.contactus ? 'terms-conditions-heading term-tab-clicked' : 'terms-conditions-heading' } onClick={
          ()=>{
            setTabData((tabData) => {
              return {...tabData, contactus:!tabData.contactus}
            })
          }
        }>
        <div>Contact Us</div>
        <div><KeyboardArrowDownIcon/></div>
        </div>
        {tabData.contactus ? <ContactUs/> :""}
        </div>
       </div>
       
    </div>
    <Rightside/>
    </>

  )
}

export default TermsConditions;