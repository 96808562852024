import React from "react";
import "./Kyc.css";
import Navbar from "../../components/navbar/Navbar";
import Leftside from "../../components/leftside/Leftside";
import KycForm from "./kyc_form/KycForm";

const Kyc = () => {
  return (
    <>
      <Navbar />
      <Leftside>
        <KycForm />
      </Leftside>
    </>
  );
};

export default Kyc;
