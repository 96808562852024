import React from "react";
import "./RoomCodeBottom.css";
import LudoKingDownload from "./ludo_king_download/LudoKingDownload";
import BottomInfoCard from "./bottom_info_card/BottomInfoCard";
import RoomCodeGameRules from "./room_code_game_rules/RoomCodeGameRules";
import MatchStatus from "./match_status/MatchStatus";
// import WinCard from "./match_status/win_card/WinCard";

const RoomCodeBottom = ({ setStatus, Game, fecthStatus }) => {
  return (
    <>
      <LudoKingDownload />
      <BottomInfoCard />
      <RoomCodeGameRules />
      <MatchStatus
        setStatus={setStatus}
        Game={Game}
        fecthStatus={fecthStatus}
      />
    </>
  );
};

export default RoomCodeBottom;
