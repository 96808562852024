import React from "react";
import "./WithdrawCash.css";
import Leftside from "../../components/leftside/Leftside";
import Navbar from "../../components/navbar/Navbar";
import WithdrawData from "./withdraw_data/WithdrawData";

const WithdrawCash = () => {
  return (
    <>
      <Navbar />
      <Leftside>
        <WithdrawData />
      </Leftside>
    </>
  );
};

export default WithdrawCash;
