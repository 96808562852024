import React, { useContext } from 'react'
import LeftSideCard from '../card/LeftSideCard'
import Navbar from "../navbar/Navbar"
import Bottombar from '../bottombar/Bottombar'
import Rightside from '../rightside/Rightside'
import DataContext from '../../context/DataContext'
import SupportIcon from '../support_icon/SupportIcon'


const Leftside = ({ children }) => {
  const { state, dispatch } = useContext(DataContext);
  return (
    <>
      {/* <Navbar/> */}
      <LeftSideCard >
        {children}
      </LeftSideCard>
      {state.isAuth ? <Bottombar /> : ""}
      {!state.isAuth ? <SupportIcon /> : ""}
    </>
  )
}

export default Leftside