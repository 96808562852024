import React, { useContext } from "react";
import "./Sidebar.css";
import sidebar_log from "../../assets/images/logo.jpg";
import CloseIcon from "@mui/icons-material/Close";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WavingHandIcon from "@mui/icons-material/WavingHand";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import GavelIcon from "@mui/icons-material/Gavel";
import DataContext from "../../context/DataContext";
import { Link } from "react-router-dom";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HistoryIcon from "@mui/icons-material/History";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import RedeemIcon from "@mui/icons-material/Redeem";

const Sidebar = () => {
  const { state, dispatch } = useContext(DataContext);
  // console.log("User details :- ", state.user_data.name);
  let user_name = state.user_data.name;

  const sidebarhandler = () => {
    dispatch({ type: "CLICKED_FOR_SIDEBAR" });
  };
  return (
    <div
      className={
        state.sidebar
          ? " sidebarContainer sidebarContainer-active"
          : "sidebarContainer"
      }
    >
      <div className="sidebar-heading">
        <div className="heading-logo">
          <Link to="/">
            <img
              width="30px"
              height="30px"
              src={sidebar_log}
              alt="KD124"
              onClick={sidebarhandler}
            />
          </Link>
          <p>KD124</p>
        </div>
        <div className="heading-cross" onClick={sidebarhandler}>
          <CloseIcon sx={{ fontSize: 34, fontWeight: "900" }} />
        </div>
      </div>

      <div className="sidbar-content">
        <div className="sidebar-greet">
          Hey
          <WavingHandIcon /> ,{!state.isAuth ? "Welcome" : ` ${user_name}`}
        </div>
        <div className="sidebar-items">
          <Link to={state.isAuth ? "/" : "/login"}>
            <div className="sidebar-item" onClick={sidebarhandler}>
              <PlayCircleIcon sx={{ fontSize: 30 }} />
              Play
            </div>
          </Link>

          {!state.isAuth ? (
            <Link to="/login" onClick={sidebarhandler}>
              <div className="sidebar-item">
                <HowToRegIcon sx={{ fontSize: 30 }} />
                Register
              </div>
            </Link>
          ) : (
            ""
          )}

          {state.isAuth ? (
            <Link to="/wallet" onClick={sidebarhandler}>
              <div className="sidebar-item">
                <AccountBalanceWalletIcon sx={{ fontSize: 30 }} />
                Wallet
              </div>
            </Link>
          ) : (
            ""
          )}

          {state.isAuth ? (
            <Link to="/history" onClick={sidebarhandler}>
              <div className="sidebar-item">
                <HistoryIcon sx={{ fontSize: 30 }} />
                History
              </div>
            </Link>
          ) : (
            ""
          )}

          {state.isAuth ? (
            <Link to="/profile" onClick={sidebarhandler}>
              <div className="sidebar-item">
                <AccountCircleIcon sx={{ fontSize: 30 }} />
                Profile
              </div>
            </Link>
          ) : (
            ""
          )}

          {state.isAuth ? (
            <Link to="/refer" onClick={sidebarhandler}>
              <div className="sidebar-item">
                <RedeemIcon sx={{ fontSize: 30 }} />
                Refer & Earn
              </div>
            </Link>
          ) : (
            ""
          )}

          <Link to="/support">
            <div className="sidebar-item" onClick={sidebarhandler}>
              <SupportAgentIcon sx={{ fontSize: 30 }} />
              Support
            </div>
          </Link>

          <Link to="/term-conditions">
            <div className="sidebar-item" onClick={sidebarhandler}>
              <GavelIcon sx={{ fontSize: 30 }} />
              Legal Terms
            </div>
          </Link>
        </div>
      </div>
      <div className="navbar-footer">Copyright © 2024, All rights reserved</div>
    </div>
  );
};

export default Sidebar;
