import React from "react";
import "./Topbar.css";

const Topbar = () => {
  return (
    <div className="topbar-container">
      Commission: 5% ◉ Referral: 2% For All Games
    </div>
  );
};

export default Topbar;
