import React from "react";
import LoginForm from "./login_form/LoginForm";
import Navbar from "../../components/navbar/Navbar";
import Leftside from "../../components/leftside/Leftside";

const Login = () => {
  return (
    <>
      <Navbar />
      <Leftside>
        <LoginForm />
      </Leftside>
    </>
  );
};

export default Login;
